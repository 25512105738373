// export const get_date_with_user_time_zone = (date, format) => {
//   var moment = require("moment-timezone");
//   var new_date = moment.tz(date, admin_time_zone);
//   return moment(new_date).tz(user_profile.time_zone).format(format);
import moment from "moment/moment";
import millify from "millify";
import { s3baseUrl } from "src/config/config";
import _ from "lodash";

// };

export const MAX_RECORDING_MINUTES = 5;
export const LONG_TEXT_LIMIT = 200;
export const FEED_TXET_LIMIT = 300;

export const COMMUNITY_LEVELS = [
  {
    name: "all",
    title: "All",
  },
  {
    name: "dynamite",
    title: "Dynamite",
  },
  {
    name: "pta",
    title: "PTA",
  },
  {
    name: "elite",
    title: "Elite",
  },
  {
    name: "mastery",
    title: "Mastery",
  },
];

export const COMMUNITY_LEVELS_Filter = [
  {
    name: "dynamite",
    title: "Dynamite",
  },
  {
    name: "pta",
    title: "PTA",
  },
  {
    name: "elite",
    title: "Elite",
  },
  {
    name: "mastery",
    title: "Mastery",
  },
];

export const CREATED_FOR = [
  {
    created_for: "lesson",
    title: "Lesson Questions",
  },
  {
    created_for: "programme",
    title: "Delegate Questions",
  },
  {
    created_for: "self_image",
    title: "Self Image Questions",
  },
  {
    created_for: "page",
    title: "Page Questions",
  },
  {
    created_for: "90-day-questions",
    title: "90 Day Questions",
  },
  {
    created_for: "delegate-90-day-questions",
    title: "90 Day Questions For Delegate",
  },
  {
    created_for: "dynamite_event_video",
    title: "Dynamite Event Video Questions",
  },
];

export const urlPatternValidation = (URL) => {
  const regex = new RegExp(
    "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
  );
  return regex.test(URL);
};

export function get_kmb_number(number) {
  if (number) {
    return millify(number, { precision: 1 });
  } else {
    return number;
  }
}

export const convertCurrencyToSign = (currency) => {
  if (currency === "gbp" || currency === "GBP") {
    return "£";
  } else if (currency === "eur" || currency === "EUR") {
    return "€";
  } else if (currency === "usd") {
    return "$";
  } else {
    return "";
  }
};

export const get_date_with_user_time_zone = (
  date,
  format,
  user_time_zone,
  admin_time_zone
) => {
  var moment = require("moment-timezone");
  var admin_date_time = moment.tz(date, admin_time_zone);
  var user_date_time = admin_date_time.clone().tz(user_time_zone);
  return user_date_time.format(format);
};

export function difference_between_two_dates(date1, date2, difference_in) {
  var start_date = moment(date1);
  var end_date = moment(date2);
  var diffBwDates = end_date.diff(start_date, difference_in);
  return diffBwDates;
}

export function sort_array_based_on_createdAt(conversations) {
  return conversations.sort((a, b) => {
    const createdAtA = new Date(a.last_message_date_time || a.createdAt);
    const createdAtB = new Date(b.last_message_date_time || b.createdAt);
    return createdAtB - createdAtA;
  });
}

export const TIME_ZONE_CONVERSION = (
  date,
  format,
  from_time_zone,
  to_time_zone
) => {
  var moment = require("moment-timezone");
  let formated_date = moment(date, "YYYY-MM-DD HH:mm:ss").format(
    "YYYY-MM-DD HH:mm"
  );
  let momentObj = moment.tz(
    formated_date,
    "YYYY-MM-DD HH:mm:ss",
    from_time_zone
  );
  let final_date = moment(momentObj).tz(to_time_zone).format(format);
  return final_date;
};
export const current_time_with_timezone = (time_zone, format) => {
  return moment().tz(time_zone).format(format);
};

export const capitalizeFirst = (str) => {
  if (!str) {
    return;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const splitName = (name) => {
  let first_name = "";
  let last_name = "";
  if (name) {
    const nameParts = name.trim().split(" ");
    first_name = nameParts[0];
    last_name = nameParts.length > 1 ? nameParts.slice(1).join(" ") : "";
  }
  return { first_name, last_name };
};

export const decode_markdown = (text) => {
  var outputText = text.replace(/\[(.*?)\]\s?\(.*?\)/g, "$1");
  var replaced = outputText.replace(/\*/g, "");
  return replaced;
};

export const decode_whatsapp = (inputText) => {
  // Replace underscore surrounded text with <i> tags for italic
  const italicPattern = /_([^_]+)_/g;
  let formattedText = inputText.replace(italicPattern, "<i>$1</i>");
  // Replace double asterisk surrounded text with <b> tags for bold
  const boldPattern = /\*([^_]+)\*/g;
  formattedText = formattedText.replace(boldPattern, "<b>$1</b>");

  return formattedText;
};

export const change_text_for_note = (text) => {
  const cleanedText = text.replace(/\[.*?\]/g, "");

  const cleanedTextss = cleanedText.replace(/[*()]/g, "");

  return cleanedTextss;
};

export const handleImageExtensions = (menu) => {
  let val = menu?.map((el) => {
    return '"' + el.substring(1).toLowerCase() + '"' + ",";
  });
  let unique = val?.filter((item, i, ar) => ar.indexOf(item) === i);
  return unique;
};

export function remove_uplicate_users(users) {
  const seenIds = new Set();
  return users.filter((user) => {
    if (seenIds.has(user._id)) {
      return false;
    } else {
      seenIds.add(user._id);
      return true;
    }
  });
}

export function string_avatar(name) {
  if (name) {
    const splitted = name.split(" ");
    if (splitted.length > 1) {
      return `${splitted[0][0]}${splitted[1][0]}`.toUpperCase();
    } else if (splitted.length === 1) {
      return `${splitted[0][0]}`.toUpperCase();
    }
  }
}

const handle_get_month = (date) => {
  let startOfMonth = moment(date).startOf("month");
  let dayOfWeek = startOfMonth.day();
  let previousSunday = startOfMonth.subtract(dayOfWeek, "days");
  let previousSundayDate = previousSunday.toDate();

  let endMonth = moment(date).endOf("month");
  let endMonthDays = Math.abs(endMonth.day() - 0 - 6); // 0 represents Sunday
  let nextSunday = endMonth.add(endMonthDays, "days");
  let nextSaturdayDate = nextSunday.toDate();
  let currentMonth = startOfMonth.add(7, "days");

  return {
    start_date: previousSundayDate,
    end_date: nextSaturdayDate,
    currentMonth: currentMonth,
  };
};

export const get_view_dates = (tool) => {
  if (tool == "month") {
    return handle_get_month(new Date());
  }

  let startOfMonth = moment().startOf(tool);
  let daysToSubtract = startOfMonth.day() - 0; // 0 represents Sunday
  let previousSunday = startOfMonth.subtract(daysToSubtract, "days");
  let previousSundayDate = previousSunday.toDate();
  let nextSaturday = startOfMonth.add(42, "days");
  let nextSaturdayDate = nextSaturday.toDate();

  return {
    start_date: previousSundayDate,
    end_date: nextSaturdayDate,
  };
};

export const handle_upgrade_month = (date) => {
  date = moment(date).add(1, "month").toDate();
  return handle_get_month(date);
};

export const handle_downgrade_month = (date) => {
  date = moment(date).subtract(1, "month").toDate();
  return handle_get_month(date);
};

export const get_short_string = (str, limit = 30) => {
  return str && str.length < limit ? str : str?.slice(0, limit) + "...";
};

export const count_chars = (str) => {
  if (!str) return 0;
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, "text/html");
  function countText(node) {
    let charCount = 0;
    function traverse(node) {
      if (node.nodeType === Node.TEXT_NODE) {
        charCount += node.textContent.length;
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        Array.from(node.childNodes).forEach((childNode) => traverse(childNode));
      }
    }
    traverse(node);
    return charCount;
  }
  const charCount = countText(doc.body);
  return charCount;
};

export const post_description = (str, limit = 500) => {
  if (!str) return "";
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, "text/html");
  function extractText(node, limit) {
    let result = "";
    let charCount = 0;
    function traverse(node) {
      if (charCount >= limit) return;
      if (node.nodeType === Node.TEXT_NODE) {
        const remainingChars = limit - charCount;
        const text = node.textContent.slice(0, remainingChars);
        result += text;
        charCount += text.length;
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        result += `<${node.nodeName.toLowerCase()}`;
        Array.from(node.attributes).forEach((attr) => {
          result += ` ${attr.name}="${attr.value}"`;
        });
        result += ">";

        Array.from(node.childNodes).forEach((childNode) => traverse(childNode));
        result += `</${node.nodeName.toLowerCase()}>`;
      }
    }
    traverse(node);
    return result;
  }

  const extractedText = extractText(doc.body, limit);
  return extractedText + (extractedText.length < str.length ? "..." : "");
};

export const replaceName = (text, name) => {
  const replaceString = text.replace("{Name}", name);
  return replaceString;
};

export function generateRandomID(length) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let id = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    id += characters.charAt(randomIndex);
  }
  return id;
}

// export const replaceUserNamesWithHTML = (string, users, editable) => {
//   // Normalize line breaks
//   string = string.replace(/\r\n/g, "\n");

//   function generateUserHTML(user) {
//     const random_id = user._id + "-" + generateRandomID(16);
//     return `<span spellCheck="false" contentEditable="false" class="mentioned-name" id="${random_id}">${user.first_name} ${user.last_name}</span>`;
//   }
//   console.log(users, "usersusersusers");
//   if (users) {
//     // Sort users based on offset in descending order
//     users.sort((a, b) => b.offset - a.offset);

//     // Replace mentions
//     users.forEach((user) => {
//       const { offset, length } = user;
//       const userHTML = generateUserHTML(user);
//       string =
//         string.slice(0, offset) + userHTML + string.slice(offset + length);
//     });

//     if (editable) {
//       // Replace newlines with <br> tags for editable content
//       string = string.replace(/\n/g, "<br>");
//     }
//   }

//   return string;
// };
export const replaceUserNamesWithHTML = (string, users, editable) => {
  // Normalize line breaks
  string = string.replace(/\r\n/g, "\n");

  function generateUserHTML(user) {
    const random_id = user._id + "-" + generateRandomID(16);
    return `<span spellCheck="false" contentEditable="false" class="mentioned-name" id="${random_id}">${user.first_name} ${user.last_name}</span>`;
  }

  // Check if users array is valid and not null
  if (users && Array.isArray(users) && users.length > 0) {
    // Sort users based on offset in descending order, ignoring null entries
    users = users.filter((user) => user !== null); // Remove null entries from users array
    users.sort((a, b) => b.offset - a.offset);

    // Replace mentions
    users.forEach((user) => {
      if (user && user.offset != null && user.length != null) {
        // Check for null values in user object
        const { offset, length } = user;
        const userHTML = generateUserHTML(user);
        string =
          string.slice(0, offset) + userHTML + string.slice(offset + length);
      }
    });

    if (editable) {
      // Replace newlines with <br> tags for editable content
      string = string.replace(/\n/g, "<br>");
    }
  }

  return string;
};

export const replace_created_for = (text) => {
  let replace_string = "";
  if (text) {
    replace_string = text.replace(/-/g, " ").replace(/_/g, " ");
  }
  return replace_string;
};

export const BOOKING_STATUS = [
  {
    name: "pending",
    label: "Pending",
    bgcolor: "rgb(24, 144, 255)",
  },
  {
    name: "no_action",
    label: "No Action",
    bgcolor: "rgb(204 9 217)",
  },
  {
    name: "reschedule",
    label: "Rescheduled",
    bgcolor: "#6c757d",
  },
  {
    name: "complete",
    label: "Completed",
    bgcolor: "rgb(84, 214, 44)",
  },
  {
    name: "cancelled",
    label: "Cancelled",
    bgcolor: "rgb(255, 72, 66)",
  },
  {
    name: "no_show",
    label: "No Show",
    bgcolor: "rgb(231 116 18)",
  },
];

export const is_small_screen = () => {
  if (window.innerWidth < 500) {
    return true;
  } else {
    return false;
  }
};
export const getImages = (data) => {
  let galleryArray = [];
  let galleryObject = {};
  data.map((gallery, index) => {
    galleryObject = {
      original: s3baseUrl + gallery.thumbnail_1,
      thumbnail: s3baseUrl + gallery.thumbnail_1,
    };
    galleryArray.push(galleryObject);
  });
  return galleryArray;
};

export const dd_date_format = (date) => {
  return moment(date).format("DD-MM-YYYY");
};

export const api_date_format = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const show_proper_words = (text) => {
  let replace_string = "";
  if (text) {
    // Replace hyphens and underscores with spaces
    replace_string = text.replace(/[-_]/g, " ");
    // Capitalize the first letter of every word
    replace_string = replace_string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  return replace_string;
};

export const NOTIFICATIONS_ARRAY = [
  {
    label: "Email",
    name: "email_notification_access",
    show_preview: true,
  },
  {
    label: "Notification",
    name: "push_notification_access",
  },
  {
    label: "WhatsApp",
    name: "whatsapp_notification_access",
  },
  {
    label: "Message",
    name: "message_notification_access",
  },
];

export const NOTIFICATIONS_ARRAY_WITH_SMS = [
  {
    label: "Email",
    name: "email_notification_access",
    show_preview: true,
  },
  {
    label: "Notification",
    name: "push_notification_access",
  },
  {
    label: "WhatsApp",
    name: "whatsapp_notification_access",
  },
  {
    label: "Message",
    name: "message_notification_access",
  },
  {
    label: "SMS",
    name: "sms_notification_access",
  },
];

export const full_page_popup_routes = [
  "/calendar-events/add-event",
  "/calendar-events/edit-event",
  "/calendar-events/edit-iteration",
  "/delegate-events/add-event",
  "/delegate-events/edit-event",
  "/delegate-events/edit-iteration",
];

export const getRandomColor = () => {
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);
  const redHex = red.toString(16).padStart(2, "0");
  const greenHex = green.toString(16).padStart(2, "0");
  const blueHex = blue.toString(16).padStart(2, "0");
  const color = `#${redHex}${greenHex}${blueHex}`;
  return color;
};

export const MONTHS_ARRAY = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

export function remove_before_dash(inputString) {
  const lastHyphenIndex = inputString.lastIndexOf("-");
  if (lastHyphenIndex !== -1) {
    return inputString.slice(lastHyphenIndex + 1);
  }
  return inputString;
}

export const get_full_name = (name) => {
  return name.first_name + " " + name.last_name + " " + "(" + name.email + ")";
};

export const YEARS_ARRAY = [
  2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030,
];

export const DEFAULT_IMAGE =
  "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png";

const compareObjects = (obj1, obj2) => {
  return _.isEqual(obj1, obj2);
};

export const check_filter_exist = (array, data) => {
  return array.find((item) => compareObjects(item.filter_object, data));
};

export const TimeZones = [
  "Europe/Andorra",
  "Asia/Dubai",
  "Asia/Kabul",
  "Europe/Tirane",
  "Asia/Yerevan",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville", // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  "Antarctica/Mawson",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Cordoba",
  "America/Argentina/Salta",
  "America/Argentina/Jujuy",
  "America/Argentina/Tucuman",
  "America/Argentina/Catamarca",
  "America/Argentina/La_Rioja",
  "America/Argentina/San_Juan",
  "America/Argentina/Mendoza",
  "America/Argentina/San_Luis",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Ushuaia",
  "Pacific/Pago_Pago",
  "Europe/Vienna",
  "Australia/Lord_Howe",
  "Antarctica/Macquarie",
  "Australia/Hobart",
  "Australia/Currie",
  "Australia/Melbourne",
  "Australia/Sydney",
  "Australia/Broken_Hill",
  "Australia/Brisbane",
  "Australia/Lindeman",
  "Australia/Adelaide",
  "Australia/Darwin",
  "Australia/Perth",
  "Australia/Eucla",
  "Asia/Baku",
  "America/Barbados",
  "Asia/Dhaka",
  "Europe/Brussels",
  "Europe/Sofia",
  "Atlantic/Bermuda",
  "Asia/Brunei",
  "America/La_Paz",
  "America/Noronha",
  "America/Belem",
  "America/Fortaleza",
  "America/Recife",
  "America/Araguaina",
  "America/Maceio",
  "America/Bahia",
  "America/Sao_Paulo",
  "America/Campo_Grande",
  "America/Cuiaba",
  "America/Santarem",
  "America/Porto_Velho",
  "America/Boa_Vista",
  "America/Manaus",
  "America/Eirunepe",
  "America/Rio_Branco",
  "America/Nassau",
  "Asia/Thimphu",
  "Europe/Minsk",
  "America/Belize",
  "America/St_Johns",
  "America/Halifax",
  "America/Glace_Bay",
  "America/Moncton",
  "America/Goose_Bay",
  "America/Blanc-Sablon",
  "America/Toronto",
  "America/Nipigon",
  "America/Thunder_Bay",
  "America/Iqaluit",
  "America/Pangnirtung",
  "America/Atikokan",
  "America/Winnipeg",
  "America/Rainy_River",
  "America/Resolute",
  "America/Rankin_Inlet",
  "America/Regina",
  "America/Swift_Current",
  "America/Edmonton",
  "America/Cambridge_Bay",
  "America/Yellowknife",
  "America/Inuvik",
  "America/Creston",
  "America/Dawson_Creek",
  "America/Fort_Nelson",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Dawson",
  "Indian/Cocos",
  "Europe/Zurich",
  "Africa/Abidjan",
  "Pacific/Rarotonga",
  "America/Santiago",
  "America/Punta_Arenas",
  "Pacific/Easter",
  "Asia/Shanghai",
  "Asia/Urumqi",
  "America/Bogota",
  "America/Costa_Rica",
  "America/Havana",
  "Atlantic/Cape_Verde",
  "America/Curacao",
  "Indian/Christmas",
  "Asia/Nicosia",
  "Asia/Famagusta",
  "Europe/Prague",
  "Europe/Berlin",
  "Europe/Copenhagen",
  "America/Santo_Domingo",
  "Africa/Algiers",
  "America/Guayaquil",
  "Pacific/Galapagos",
  "Europe/Tallinn",
  "Africa/Cairo",
  "Africa/El_Aaiun",
  "Europe/Madrid",
  "Africa/Ceuta",
  "Atlantic/Canary",
  "Europe/Helsinki",
  "Pacific/Fiji",
  "Atlantic/Stanley",
  "Pacific/Chuuk",
  "Pacific/Pohnpei",
  "Pacific/Kosrae",
  "Atlantic/Faroe",
  "Europe/Paris",
  "Europe/London",
  "Asia/Tbilisi",
  "America/Cayenne",
  "Africa/Accra",
  "Europe/Gibraltar",
  "America/Godthab",
  "America/Danmarkshavn",
  "America/Scoresbysund",
  "America/Thule",
  "Europe/Athens",
  "Atlantic/South_Georgia",
  "America/Guatemala",
  "Pacific/Guam",
  "Africa/Bissau",
  "America/Guyana",
  "Asia/Hong_Kong",
  "America/Tegucigalpa",
  "America/Port-au-Prince",
  "Europe/Budapest",
  "Asia/Jakarta",
  "Asia/Pontianak",
  "Asia/Makassar",
  "Asia/Jayapura",
  "Europe/Dublin",
  "Asia/Jerusalem",
  "Asia/Kolkata",
  "Indian/Chagos",
  "Asia/Baghdad",
  "Asia/Tehran",
  "Atlantic/Reykjavik",
  "Europe/Rome",
  "America/Jamaica",
  "Asia/Amman",
  "Asia/Tokyo",
  "Africa/Nairobi",
  "Asia/Bishkek",
  "Pacific/Tarawa",
  "Pacific/Enderbury",
  "Pacific/Kiritimati",
  "Asia/Pyongyang",
  "Asia/Seoul",
  "Asia/Almaty",
  "Asia/Qyzylorda",
  "Asia/Qostanay", // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  "Asia/Aqtobe",
  "Asia/Aqtau",
  "Asia/Atyrau",
  "Asia/Oral",
  "Asia/Beirut",
  "Asia/Colombo",
  "Africa/Monrovia",
  "Europe/Vilnius",
  "Europe/Luxembourg",
  "Europe/Riga",
  "Africa/Tripoli",
  "Africa/Casablanca",
  "Europe/Monaco",
  "Europe/Chisinau",
  "Pacific/Majuro",
  "Pacific/Kwajalein",
  "Asia/Yangon",
  "Asia/Ulaanbaatar",
  "Asia/Hovd",
  "Asia/Choibalsan",
  "Asia/Macau",
  "America/Martinique",
  "Europe/Malta",
  "Indian/Mauritius",
  "Indian/Maldives",
  "America/Mexico_City",
  "America/Cancun",
  "America/Merida",
  "America/Monterrey",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Chihuahua",
  "America/Ojinaga",
  "America/Hermosillo",
  "America/Tijuana",
  "America/Bahia_Banderas",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Africa/Maputo",
  "Africa/Windhoek",
  "Pacific/Noumea",
  "Pacific/Norfolk",
  "Africa/Lagos",
  "America/Managua",
  "Europe/Amsterdam",
  "Europe/Oslo",
  "Asia/Kathmandu",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Auckland",
  "Pacific/Chatham",
  "America/Panama",
  "America/Lima",
  "Pacific/Tahiti",
  "Pacific/Marquesas",
  "Pacific/Gambier",
  "Pacific/Port_Moresby",
  "Pacific/Bougainville",
  "Asia/Manila",
  "Asia/Karachi",
  "Europe/Warsaw",
  "America/Miquelon",
  "Pacific/Pitcairn",
  "America/Puerto_Rico",
  "Asia/Gaza",
  "Asia/Hebron",
  "Europe/Lisbon",
  "Atlantic/Madeira",
  "Atlantic/Azores",
  "Pacific/Palau",
  "America/Asuncion",
  "Asia/Qatar",
  "Indian/Reunion",
  "Europe/Bucharest",
  "Europe/Belgrade",
  "Europe/Kaliningrad",
  "Europe/Moscow",
  "Europe/Simferopol",
  "Europe/Kirov",
  "Europe/Astrakhan",
  "Europe/Volgograd",
  "Europe/Saratov",
  "Europe/Ulyanovsk",
  "Europe/Samara",
  "Asia/Yekaterinburg",
  "Asia/Omsk",
  "Asia/Novosibirsk",
  "Asia/Barnaul",
  "Asia/Tomsk",
  "Asia/Novokuznetsk",
  "Asia/Krasnoyarsk",
  "Asia/Irkutsk",
  "Asia/Chita",
  "Asia/Yakutsk",
  "Asia/Khandyga",
  "Asia/Vladivostok",
  "Asia/Ust-Nera",
  "Asia/Magadan",
  "Asia/Sakhalin",
  "Asia/Srednekolymsk",
  "Asia/Kamchatka",
  "Asia/Anadyr",
  "Asia/Riyadh",
  "Pacific/Guadalcanal",
  "Indian/Mahe",
  "Africa/Khartoum",
  "Europe/Stockholm",
  "Asia/Singapore",
  "America/Paramaribo",
  "Africa/Juba",
  "Africa/Sao_Tome",
  "America/El_Salvador",
  "Asia/Damascus",
  "America/Grand_Turk",
  "Africa/Ndjamena",
  "Indian/Kerguelen",
  "Asia/Bangkok",
  "Asia/Dushanbe",
  "Pacific/Fakaofo",
  "Asia/Dili",
  "Asia/Ashgabat",
  "Africa/Tunis",
  "Pacific/Tongatapu",
  "Europe/Istanbul",
  "America/Port_of_Spain",
  "Pacific/Funafuti",
  "Asia/Taipei",
  "Europe/Kiev",
  "Europe/Uzhgorod",
  "Europe/Zaporozhye",
  "Pacific/Wake",
  "America/New_York",
  "America/Detroit",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Indiana/Indianapolis",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Vevay",
  "America/Chicago",
  "America/Indiana/Tell_City",
  "America/Indiana/Knox",
  "America/Menominee",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/North_Dakota/Beulah",
  "America/Denver",
  "America/Boise",
  "America/Phoenix",
  "America/Los_Angeles",
  "America/Anchorage",
  "America/Juneau",
  "America/Sitka",
  "America/Metlakatla",
  "America/Yakutat",
  "America/Nome",
  "America/Adak",
  "Pacific/Honolulu",
  "America/Montevideo",
  "Asia/Samarkand",
  "Asia/Tashkent",
  "America/Caracas",
  "Asia/Ho_Chi_Minh",
  "Pacific/Efate",
  "Pacific/Wallis",
  "Pacific/Apia",
  "Africa/Johannesburg",
];

export const TIME_ZONE_CONVERSION_new = (
  date,
  format,
  from_time_zone,
  to_time_zone
) => {
  const moment = require("moment-timezone");

  // Parse the input date using moment.tz to handle both time zones
  let momentObj = moment.tz(date, from_time_zone);

  // Convert to the target time zone and format the date
  let final_date = momentObj.tz(to_time_zone).format(format);

  return final_date;
};
