import React, { useEffect, useState } from "react";
import { Avatar, AvatarGroup, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  feed_poll_details_api,
  manage_poll_answer_api,
  poll_option_user_list_api,
} from "src/DAL/Community/PollFeed";
import { TIME_ZONE_CONVERSION } from "src/utils/constants";
import { s3baseUrl } from "src/config/config";
import { useSnackbar } from "notistack";
import { useContentSetting } from "src/Hooks/ContentSettingState";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
    marginBottom: "20%",
  },
}));

export default function PollDetail(props) {
  const { selectedFeed, handleOptionResult } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { userInfo, adminTimeZone, socket, feedSettings } = useContentSetting();
  const [feedInfo, setFeedInfo] = useState({});
  const [pollOptions, setPollOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleDetail = async () => {
    const result = await feed_poll_details_api(selectedFeed._id);
    if (result.code === 200) {
      setFeedInfo(result.feed_obj);
      setPollOptions(result.options_array);
      setSelected(result.selected_options);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleAnswer = async (option) => {
    if (feedInfo.poll_info.poll_status == "expired") return;
    const postData = {
      feed_id: selectedFeed._id,
      option_id: option._id,
    };

    const result = await manage_poll_answer_api(postData);
    if (result.code === 200) {
      setFeedInfo(result.feed_obj);
      setPollOptions(result.feed_obj.poll_info.options);
      setSelected(result.feed_obj.selected_options);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handle_poll_answered = (result) => {
    setPollOptions(result.feed_obj.poll_info.options);
  };

  const handle_poll_expired = (data) => {
    let find_feed = data.feeds.find(
      (item) => item.feed_id === selectedFeed._id
    );
    if (find_feed) {
      setFeedInfo((feed) => {
        feed.poll_info.poll_status = "expired";
        return feed;
      });
    }
  };

  useEffect(() => {
    handleDetail();
    socket.on("live_feed_room_reciever", (data) => {
      if (data.action === "poll_answered") {
        handle_poll_answered(data);
      } else if (data.action === "poll_expired") {
        handle_poll_expired(data);
      }
    });
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="poll-result-heading">Poll Statement</div>
      <div className="poll-statement color-white">{feedInfo?.description}</div>
      <div className="poll-result-heading mt-2">Expiration</div>
      <div className="poll-expiry-date">
        {`${
          feedInfo?.poll_info.poll_status === "expired" ? "Poll expired on" : ""
        } ${TIME_ZONE_CONVERSION(
          feedInfo?.poll_info?.expiry_date_time,
          " DD-MM-YYYY [at] hh:mm A",
          adminTimeZone,
          userInfo.time_zone
        )}`}
      </div>
      <div className="poll-result-heading mt-2">Responses</div>
      {pollOptions?.length > 0 &&
        pollOptions.map((option) => {
          let find_answer = selected?.find((ans) => ans._id == option._id);
          return (
            <>
              <div
                className={`feed-poll-option mb-1 ${
                  feedInfo?.poll_info.poll_status === "expired"
                    ? "poll-expired"
                    : ""
                }`}
                onClick={() => handleAnswer(option)}
              >
                <div className={`p-1 ${find_answer ? "answered" : ""}`}>
                  <div className="d-flex justify-content-between px-1">
                    <div>{option.text}</div>
                    {option.votes > 0 && (
                      <div className="option-count">{option.votes}</div>
                    )}
                  </div>
                </div>
              </div>
              {option?.answers && option?.answers.length > 0 && (
                <div
                  className="d-flex justify-content-start cursor-pointer"
                  onClick={() => handleOptionResult(option)}
                >
                  <AvatarGroup max={4} className="feed-poll-avatars">
                    {option.answers.map((item, index) => {
                      let name = item.user_info_action_by;
                      return (
                        <Avatar
                          key={index}
                          alt={name?.name}
                          src={s3baseUrl + name?.profile_image}
                        />
                      );
                    })}
                  </AvatarGroup>
                </div>
              )}
            </>
          );
        })}
    </>
  );
}
