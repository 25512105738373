import React, { useEffect, useState } from "react";
import { Chip, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import { all_questions_listing_api } from "src/DAL/GeneralQuestions/GeneralQuestions";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { replace_created_for } from "src/utils/constants";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";
import QuestionAnswerFilter from "./QuestionAnswerFilter";
import { get_root_value } from "src/utils/domUtils";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function QuestionsAnswersList({ user_type, heading }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoading, setIsLoading] = useState(true);
  const [answersData, setAnswersData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filterData, setFilterData] = useState({
    created_for: null,
  });
  const [filterDataUpdated, setFilterDataUpdated] = useState({
    created_for: null,
  });

  const EMPTY_FILTER = {
    created_for: null,
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageCount(1);
  };

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const handleClearFilter = () => {
    setFilterData(EMPTY_FILTER);
    localStorage.removeItem("question_answers_filter_data");
    getAnswersListing(EMPTY_FILTER);
    setFilterDrawerState(false);
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    filterData.search_text = searchText;
    console.log(filterData, "filterDatafilterData");
    setFilterDataUpdated(filterData);
    getAnswersListing(filterData);
    localStorage.setItem(
      "question_answers_filter_data",
      JSON.stringify(filterData)
    );
    handleCloseFilterDrawer();
  };

  const getAnswersListing = async (data) => {
    setIsLoading(true);
    console.log(data, "data for filter");
    let created_for = null;
    if (data.created_for) {
      created_for = data.created_for?.created_for;
    }

    let postData = {
      ...data,
      created_for: created_for,
    };
    const result = await all_questions_listing_api(page, rowsPerPage, postData);

    if (result.code == 200) {
      const answers = result?.members?.map((answer, index) => {
        let image_src = "";
        if (answer?.profile_image) {
          image_src = s3baseUrl + answer.profile_image;
        } else if (answer?.image?.thumbnail_1) {
          image_src = s3baseUrl + answer.image.thumbnail_1;
        }
        return {
          ...answer,
          user_name: answer?.first_name + " " + answer?.last_name,
          user_email: answer?.email,
          title: answer?.title
            ? answer?.title
            : replace_created_for(answer.created_for),
          answered_date: moment(answer.reply_date).format("DD-MM-YYYY"),
          created_for_string: replace_created_for(answer.created_for),
          table_avatar: {
            src: image_src,
            alt: answer.first_name,
          },
        };
      });

      setAnswersData(answers);
      setTotalCount(result.toal_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = (value) => {
    let check_user = false;
    if (value.created_for == "self_image") {
      check_user = true;
    }
    navigate(
      `${window.location.pathname}/answers-detail?created_for_id=${
        value.created_for_id ? value.created_for_id : ""
      }&member_id=${value.member_id}&created_for=${
        value.created_for
      }&check_user=${check_user}`
    );
  };

  const MENU_OPTIONS = [
    {
      label: "Answers Detail",
      icon: "akar-icons:edit",
      handleClick: handleNavigate,
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangeCreatedFor = (value) => {
    setFilterData((values) => ({ ...values, created_for: value }));
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };
  const handleDeleteProgramChips = (filterDataUpdated) => {
    filterDataUpdated.created_for = null;
    setFilterDataUpdated(filterDataUpdated);
    setFilterData(filterDataUpdated);
    localStorage.setItem(
      "question_answers_filter_data",
      JSON.stringify(filterDataUpdated)
    );
    getAnswersListing(filterDataUpdated);
  };
  const handleClearFilterall = () => {
    filterDataUpdated.created_for = null;
    setFilterDataUpdated(filterDataUpdated);
    setFilterData(filterDataUpdated);
    localStorage.setItem(
      "question_answers_filter_data",
      JSON.stringify(filterDataUpdated)
    );
    getAnswersListing(filterDataUpdated);
  };
  useEffect(() => {
    setPage(0);
    setPageCount(1);
  }, [user_type]);

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("question_answers_filter_data");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      setFilterData(filter_data);
      setFilterDataUpdated(filter_data);
      if (filter_data.search_text) {
        setSearchText(filter_data.search_text);
      }
    }

    getAnswersListing(filter_data);
  }, [rowsPerPage, page, user_type]);

  const TABLE_HEAD = [
    { id: "action", label: "Action", alignRight: false, type: "action" },
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "created_for_string",
      label: "Questions Created For",
      alignRight: false,
      className: "text-capitalize cursor-pointer",
      handleClick: handleNavigate,
    },
    { id: "title", label: "Module Title", alignRight: false },
    {
      id: "table_avatar",
      label: "User Profile",
      alignRight: false,
      type: "thumbnail",
    },
    { id: "user_name", label: "User Name", alignRight: false },
    {
      id: "user_email",
      label: "User Email",
      calignRight: false,
    },
    {
      id: "answered_date",
      label: "Answered Date",
      calignRight: false,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  console.log(filterData, "filterData");
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 mb-3">
            <h2>{heading ? heading : "Questions Answers List"}</h2>
          </div>
          <div className="col-12 col-md-6 mb-3 text-end">
            <button
              className="small-contained-button me-2 mt-1 mb-4"
              onClick={handleOpenFilterDrawer}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
          </div>
        </div>
        <div className="row mb-2">
          {filterDataUpdated.created_for && (
            <div className="col-lg-12 col-sm-12">
              <b className="me-3 pt-1 ms-2">Filtered By:</b>
              <Chip
                label={filterDataUpdated.created_for.title}
                onDelete={() => handleDeleteProgramChips(filterDataUpdated)}
                className="mb-2 me-1 mt-2"
                sx={{
                  color: "white",
                }}
              />
              <span
                className="anchor-style ms-2 pt-1"
                onClick={() => handleClearFilterall(filterDataUpdated)}
                style={{ color: get_root_value("--portal-theme-primary") }}
              >
                Clear All
              </span>
            </div>
          )}
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={answersData}
          MENU_OPTIONS={MENU_OPTIONS}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
        />
      </div>
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <QuestionAnswerFilter
            filterData={filterData}
            handleChange={handleChangeCreatedFor}
            searchSubmitFilter={searchFunction}
            handleClearFilter={handleClearFilter}
          />
        }
      />
    </>
  );
}
