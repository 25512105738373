import { CircularProgress, TextField } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useEffect } from "react";
import {
  program_event_list_with_plan_api_request,
  update_porgrams_for_api,
} from "src/DAL/WebsitePages/paymentPlan";
import { s3baseUrl } from "src/config/config";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import ActiveLastBreadcrumb from "src/components/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ProgrammeAccessPaymentRequest() {
  const params = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [programsList, setProgramsList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [planDetail, setPlanDetail] = useState({});
  const [rowPerPage, setrowPerPage] = useState("");

  const getProgramsList = async () => {
    const result = await program_event_list_with_plan_api_request(
      params.plan_id,
      "program"
    );
    if (result.code == 200) {
      setPlanDetail(result.payment_request);
      let selected_programs = [];
      if (result.payment_request.programs) {
        result.payment_request.programs.map((prog) => {
          selected_programs.push({
            ...prog,
            _id: prog.program_id,
          });
        });
      }

      setSelected(selected_programs);
      var programArray = [];
      setrowPerPage(result.programs.length);
      result.programs.map((item) => {
        item.table_avatar = {
          src: s3baseUrl + item.program_images.thumbnail_3,
          alt: item.title,
        };
        programArray.push(item);
      });
      setProgramsList(programArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoadingForm(true);
    let programme = [];
    selected.map((item) => {
      var program_object = {
        program_id: item._id,
        title: item.title,
        program_access_type: item.program_access_type,
        no_of_start_days: parseInt(item.no_of_start_days, 10),
        no_of_limited_days: parseInt(item.no_of_limited_days, 10),
      };
      programme.push(program_object);
    });

    let programObject = {
      program: programme,
    };

    const result = await update_porgrams_for_api(
      params.plan_id,
      programObject,
      "program"
    );
    if (result.code == 200) {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChange = (event, name) => {
    const value = event.target.value;
    const program_id = event.target.name;

    setProgramsList((data) =>
      data.map((obj) => {
        if (obj._id === program_id) {
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );

    setSelected((data) =>
      data.map((obj) => {
        if (obj._id === program_id) {
          return { ...obj, [name]: value };
        }
        return obj;
      })
    );
  };

  const TABLE_HEAD = [
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
      className: "p-0",
    },
    { id: "title", label: "Programme Title", alignRight: false },
    {
      id: "status",
      label: "Status",
      type: "row_status",
    },
    {
      id: "no_of_start_days",
      label: "No Of Start Days",
      renderData: (row) => {
        let is_selected = selected?.find((object) => row._id === object._id);
        let no_of_start_days =
          is_selected && is_selected.no_of_start_days
            ? is_selected.no_of_start_days
            : row.no_of_start_days;

        if (row.program_access_type == "limited") {
          return (
            <TextField
              id="outlined-basic"
              variant="outlined"
              fullWidth
              size="small"
              type="number"
              required
              name={row._id}
              className="default_consultant_commission_input"
              value={no_of_start_days}
              sx={{
                "& fieldset": { border: "none" },
              }}
              onChange={(e) => {
                handleChange(e, "no_of_start_days");
              }}
            />
          );
        }
      },
    },
    {
      id: "no_of_limited_days",
      label: "No Of Limit End Days",
      renderData: (row) => {
        let is_selected = selected.find((object) => row._id === object._id);
        let no_of_limited_days =
          is_selected && is_selected.no_of_limited_days
            ? is_selected.no_of_limited_days
            : row.no_of_limited_days;

        if (row.program_access_type == "limited") {
          return (
            <TextField
              id="outlined-basic"
              variant="outlined"
              fullWidth
              size="small"
              type="number"
              required
              name={row._id}
              className="default_consultant_commission_input"
              value={no_of_limited_days}
              sx={{
                "& fieldset": { border: "none" },
              }}
              onChange={(e) => {
                handleChange(e, "no_of_limited_days");
              }}
            />
          );
        }
      },
    },
  ];

  useEffect(() => {
    getProgramsList();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  let breadCrumbMenu = [
    {
      title: "Payment Request",
      navigation: `/payment-request`,
      active: false,
    },

    {
      title: planDetail?.request_title,
      active: true,
    },
  ];

  return (
    <div className="container">
      <div className="row mb-4">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <div className="col-6">
          <h2>Manage Programme Access</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={programsList}
            selected={selected}
            setSelected={setSelected}
            checkbox_selection={true}
            pagePagination={false}
            rows_per_page_count={rowPerPage}
            hide_footer_pagination={true}
          />
        </div>
      </div>
      <div className="ms-auto text-end">
        <button
          className="small-contained-button mt-3 ml-auto"
          onClick={handleSubmit}
        >
          {isLoadingForm ? "Updating..." : "Update"}
        </button>
      </div>
    </div>
  );
}
