import {
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import {
  subteam_lisitng_api,
  Update_sales_team_access_api,
} from "src/DAL/WebsitePages/WebsitePages";
import ActiveLastBreadcrumb from "src/components/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ManageSubteamAccess() {
  const params = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [programsList, setProgramsList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [planDetail, setPlanDetail] = useState(null);
  const getProgramsList = async () => {
    const result = await subteam_lisitng_api(params.plan_id);
    if (result.code == 200) {
      setPlanDetail(result.sale_page);
      let selected_programs = [];
      result?.selected_consultants?.map((prog) => {
        selected_programs.push({
          ...prog,
          _id: prog._id,
          is_exist: true,
        });
      });

      setSelected(selected_programs);
      var programArray = [];
      result.sales_team.map((item) => {
        let is_exist = result?.selected_consultants?.find(
          (object) => object._id == item._id
        );
        if (is_exist) {
          item.quantity = is_exist.quantity;
        }
        item.name =
          item.first_name + " " + item.last_name + "(" + item.email + ")";
        item.table_avatar = {
          src: s3baseUrl + item.image?.thumbnail_1,
          alt: item.name,
        };
        item.is_exist = Boolean(is_exist);

        programArray.push(item);
      });
      setProgramsList(programArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);
    let dynamite_products = [];

    programsList.map((item) => {
      if (item.is_exist) {
        dynamite_products.push(item._id);
      }
    });

    let programObject = {
      subteam_ids: dynamite_products,
      sale_page_id: params.plan_id,
    };

    const result = await Update_sales_team_access_api(programObject);
    if (result.code == 200) {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChangeCheckbox = (row, index) => {
    setProgramsList((old) =>
      old.map((obj) => {
        if (obj._id == row._id) {
          obj.is_exist = !obj.is_exist;
        }
        return obj;
      })
    );
  };

  const TABLE_HEAD = [
    {
      id: "is_exist",
      label: "Select Users",
      renderData: (row, index) => {
        return (
          <>
            <Tooltip
              title={
                row.is_approved
                  ? ""
                  : "Team Member Not Approved Yet so can not be selected"
              }
            >
              <span className={`${row.is_approved ? "" : "disabled"}`}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={row.is_exist}
                      className={`${row.is_approved ? "" : "disabled"}`}
                    />
                  }
                  label=""
                  onChange={
                    !row.is_approved
                      ? () => {}
                      : () => handleChangeCheckbox(row, index)
                  }
                  className="px-2"
                />
              </span>
            </Tooltip>
          </>
        );
      },
    },
    {
      id: "table_avatar",
      label: "Profile Image",
      type: "thumbnail",
      className: "p-0",
    },
    {
      id: "name",
      label: "Name",
      type: "text",
    },

    {
      id: "is_approved",
      label: "Status",
      renderData: (row) => {
        return (
          <Chip
            label={row.is_approved ? "Approved" : "Pending"}
            color={row.is_approved ? "success" : "error"}
          />
        );
      },
    },
  ];

  useEffect(() => {
    getProgramsList();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  let breadCrumbMenu = [
    {
      title: planDetail.sale_page_title,
      navigation: -1,
      active: false,
    },
    {
      title: "Manage Sub Team Access",
      active: true,
    },
  ];

  return (
    <form onSubmit={handleSubmit}>
      <div className="container-fluid">
        <div className="row">
          {planDetail && (
            <div className="col-12 mb-3">
              <span>
                <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
              </span>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-12">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={programsList}
              pagePagination={true}
            />
          </div>
        </div>
        <div className="ms-auto text-end">
          <button className="small-contained-button mt-3 ml-auto">
            {isLoadingForm ? "Updating..." : "Update"}
          </button>
        </div>
      </div>
    </form>
  );
}
