import { invokeApi } from "../../bl_libs/invokeApi";
export const paymentPlansListBySalePageApi = async (id) => {
  const requestObj = {
    path: `api/payment_plan/list_payment_plan_by_sale_page/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const program_event_list_with_plan_api_request = async (
  plan_id,
  type
) => {
  const requestObj = {
    path: `api/payment_request/get_programs_and_event_data/${
      plan_id ? plan_id : ""
    }?list_data_type=${type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const update_porgrams_for_api = async (plan_id, data, type) => {
  const requestObj = {
    path: `api/payment_request/update_event_or_program_data/${plan_id}?update_type=${type}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const agreementConfigurationPaymentRequestApi = async (data, id) => {
  const requestObj = {
    path: `api/payment_request/agreement_config/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const PaymentRequestDetail = async (id) => {
  const requestObj = {
    path: `api/payment_request/detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const events_programs_product_lead_status_api = async (search) => {
  const requestObj = {
    path: `api/member/get_data_list/plan`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const paymentPlansListApi = async (id) => {
  let api_path = `api/payment_plan/list_payment_plan`;
  if (id) {
    api_path = `api/payment_plan/list_payment_plan_by_sale_page/${id}`;
  }
  const requestObj = {
    path: api_path,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const detailPaymentPlanApi = async (id) => {
  const requestObj = {
    path: `api/payment_plan/detail_payment_plan/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const add_agreeement_configuration = async (id, data) => {
  const requestObj = {
    path: `api/sale_page/update/agreement/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editPaymentPlanApi = async (data, id) => {
  const requestObj = {
    path: `api/payment_plan/edit_payment_plan/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addPaymentPlanApi = async (data) => {
  const requestObj = {
    path: `api/payment_plan/add_payment_plan`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deletePaymentPlanApi = async (id) => {
  const requestObj = {
    path: `api/payment_plan/delete_payment_plan/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const programme_list_with_plan_api = async (plan_id) => {
  const requestObj = {
    path: `api/payment_plan/get_client_programs_for_plan/${
      plan_id ? plan_id : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const update_porgrams_for_plan_api = async (plan_id, data) => {
  const requestObj = {
    path: `api/payment_plan/update_porgrams_for_plan/${plan_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_protal_list_for_plan_api = async (plan_id) => {
  const requestObj = {
    path: `api/payment_plan/get_protal_list_for_plan/${plan_id ? plan_id : ""}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const get_protal_list_for_plan_api_v1 = async (plan_id) => {
  const requestObj = {
    path: `api/payment_plan/list_portals_for_plan_by_delegate/${
      plan_id ? plan_id : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const update_portals_for_plan_api = async (plan_id, data) => {
  const requestObj = {
    path: `api/payment_plan/update_portals_for_plan/${plan_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_portals_for_plan_api_v1 = async (plan_id, data) => {
  const requestObj = {
    path: `api/payment_plan/update_portals_for_plan_by_delegate/${plan_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
