import {
  CircularProgress,
  Chip,
  Tooltip,
  Card,
  CardHeader,
  Box,
} from "@mui/material";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { AppNewsUpdate, AppWebsiteVisits } from "../components/_dashboard/app";
import { useEffect, useState } from "react";
import { dashboardApi } from "src/DAL/Login/Login";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import Chart from "react-apexcharts";
import CircleIcon from "@mui/icons-material/Circle";
import { DashboardFilter } from "src/components/DashboardFilter";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import FilterListIcon from "@mui/icons-material/FilterList";
import DashboradCountCard from "src/components/_dashboard/app/DashboradCountCard";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import moment from "moment";
import CompleteUncompleteChart from "src/components/AccountabilityTracker/CompleteUncompleteChart";
import LeaderboardCardPerformance from "src/components/AccountabilityTracker/LeaderboardCardPerformance";
import { replace_created_for } from "src/utils/constants";
import { useNavigate } from "react-router-dom";
import { s3baseUrl } from "src/config/config";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function getLastWeekData(data) {
  // Get the date one week ago
  let oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 6);
  // Initialize arrays for dates and scores
  let dates = [];
  let scores = [];
  // Array of month names
  let monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  for (let i = 0; i < 7; i++) {
    let currentDate = new Date(oneWeekAgo);
    currentDate.setDate(oneWeekAgo.getDate() + i);

    let day = String(currentDate.getDate()).padStart(2, "0");
    let month = String(currentDate.getMonth() + 1).padStart(2, "0");
    let year = currentDate.getFullYear();

    let dateStringForComparison = `${day}-${month}-${year}`;

    let found = data.find((item) => item.date === dateStringForComparison);

    if (found) {
      let dayForDisplay = currentDate.getDate();
      let monthForDisplay = monthNames[currentDate.getMonth()];
      let dateStringForDisplay = `${monthForDisplay} ${dayForDisplay}`;
      dates.push(dateStringForDisplay);
      scores.push(found.rate);
    } else {
      let dayForDisplay = currentDate.getDate();
      let monthForDisplay = monthNames[currentDate.getMonth()];

      let dateStringForDisplay = `${monthForDisplay}' ${dayForDisplay}`;

      dates.push(dateStringForDisplay);
      scores.push(0);
    }
  }
  let dataObject = { dates, scores };
  return dataObject;
}
const TABLE_HEAD = [
  {
    id: "user_name",
    label: "Name",
    alignRight: false,
  },
  {
    id: "page_title",
    label: "Booking Page",
  },
  { id: "booking_date", label: "Date", alignRight: false },
  {
    id: "status",
    label: "Booking Status",
    renderData: (row) => {
      let find_status = row.booking_status_info;
      if (find_status) {
        return (
          <Tooltip title={find_status?.title}>
            <Chip
              label={find_status?.title}
              variant="contained"
              className="booking-status-chip"
              style={{
                backgroundColor: find_status.background_color,
                color: find_status.text_color,
                height: "22px",
              }}
              size="small"
            />
          </Tooltip>
        );
      }
    },
  },
];

const get_user_name = (user_info) => {
  return (
    user_info?.first_name +
    " " +
    user_info?.last_name +
    " (" +
    user_info?.email +
    ")"
  );
};

// const get_booking_date = (booking) => {
//   const dateTime = moment().format("YYYY-MM-DD") + " " + booking.time;
//   let booking_date = `${moment(booking.date).format("DD-MM-YYYY")} (${
//     booking.time +
//     " - " +
//     moment(dateTime, "DD-MM-YYYY hh:mm A")
//       .add(booking.slot_duration, "minutes")
//       .format("hh:mm A")
//   })`;
//   return booking_date;
// };

const get_booking_date = (booking) => {
  const dateTime = moment().format("YYYY-MM-DD") + " " + booking.time;
  let booking_date = `${moment.utc(booking.date).format("DD-MM-YYYY")} (${
    booking.time +
    " - " +
    moment(dateTime, "DD-MM-YYYY hh:mm A")
      .add(booking.slot_duration, "minutes")
      .format("hh:mm A")
  })`;
  return booking_date;
};
const get_bookings_list = (booking_list) => {
  let bookings = [];
  booking_list.map((booking) => {
    let page_title = booking?.page?.sale_page_title;
    let booking_object = {
      ...booking,
      booking_date: get_booking_date(booking),
      page_title: page_title ? page_title : "N/A",
      user_name: get_user_name(booking.user_info),
    };
    bookings.push(booking_object);
  });
  return bookings;
};

export default function DashboardApp() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { consultantInfo } = useContentSetting();
  const [isLoader, setIsLoader] = useState(false);
  const [attitudeRecord, setAttitudeRecord] = useState();
  const [focusRecords, setFocusRecord] = useState();
  const [desireRecords, setDesire] = useState();
  const [disciplineRecords, setDisciplineRecords] = useState();
  const [winRecords, setwinRecords] = useState();
  const [answersData, setAnswersData] = useState([]);
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState({
    latest_bookings: [],
    upcomming_bookings: [],
  });

  const [drawerState, setDrawerState] = useState(false);
  const [inputs, setInputs] = useState({
    start_date: null,
    end_date: null,
  });

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };
  const handleNavigateAll = () => {
    navigate(`/member-answers-list`);
  };

  const getDashboardDetail = async (filterData) => {
    setIsLoader(true);
    const result = await dashboardApi(filterData ? filterData : "");
    if (result.code == 200) {
      const answers = result?.member_answer_list?.map((answer, index) => {
        let image_src = "";
        if (answer?.profile_image) {
          image_src = s3baseUrl + answer.profile_image;
        } else if (answer?.image?.thumbnail_1) {
          image_src = s3baseUrl + answer.image.thumbnail_1;
        }
        return {
          ...answer,
          user_name: answer?.first_name + " " + answer?.last_name,
          user_email: answer?.email,
          title: answer?.title
            ? answer?.title
            : replace_created_for(answer.created_for),
          answered_date: moment(answer.reply_date).format("DD-MM-YYYY"),
          created_for_string: replace_created_for(answer.created_for),
          table_avatar: {
            src: image_src,
            alt: answer.first_name,
          },
        };
      });
      setAnswersData(answers);
      result.latest_bookings = get_bookings_list(result.latest_booking_list);
      result.upcomming_bookings = get_bookings_list(
        result.upcomming_booking_list
      );
      let attitudeRecords = getLastWeekData(
        result.attitude_performance_rate_array
      );
      let focusRecords = getLastWeekData(result.focus_performance_rate_array);
      let DesireRecords = getLastWeekData(result.desire_performance_rate_array);
      let DiscplineRecords = getLastWeekData(
        result.discipline_performance_rate_array
      );
      let winRecords = getLastWeekData(result.win_note_performance_rate_array);
      setAttitudeRecord(attitudeRecords);
      setFocusRecord(focusRecords);
      setDesire(DesireRecords);
      setDisciplineRecords(DiscplineRecords);
      setwinRecords(winRecords);
      setDashboardData(result);
      setIsLoader(false);

      handleCloseDrawer();
    } else {
      setIsLoader(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleNavigate = (value) => {
    let check_user = false;
    if (value.created_for == "self_image") {
      check_user = true;
    }
    navigate(
      `${window.location.pathname}/answers-detail?created_for_id=${
        value.created_for_id ? value.created_for_id : ""
      }&member_id=${value.member_id}&created_for=${
        value.created_for
      }&check_user=${check_user}`
    );
  };

  const TABLE_HEAD_MEMBER_ANSWER_LIST = [
    { id: "number", label: "#", alignRight: false, type: "number" },

    {
      id: "user_name",
      label: "User Name",
      alignRight: false,
      className: "text-capitalize cursor-pointer",
      handleClick: handleNavigate,
    },
    { id: "title", label: "Module Title", alignRight: false },
    {
      id: "answered_date",
      label: "Answered Date",
      calignRight: false,
    },
  ];

  useEffect(() => {
    getDashboardDetail();
  }, []);

  if (isLoader === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container main-div-img">
      <div className="dashboard-background">
        <div className="row">
          <div className="col-12 mb-4 mt-4">
            <div
              className="ms-3"
              dangerouslySetInnerHTML={{
                __html: consultantInfo?.dashboard_content,
              }}
            ></div>
          </div>
          <div className="col-12 mb-4 text-end">
            <button
              className="small-contained-button"
              onClick={handleOpenDrawer}
            >
              <FilterListIcon className="me-2" />
              Filter
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-3 mb-4">
            <DashboradCountCard
              title="Today's Commission"
              count={dashboardData?.today_commission}
              icon={<CurrencyPoundIcon />}
              bgColor="34, 154, 22"
            />
          </div>
          <div className="col-12 col-md-6 col-lg-3 mb-4">
            <DashboradCountCard
              title="Pending Commission"
              count={
                dashboardData?.total_commission - dashboardData?.paid_commission
              }
              icon={<CurrencyPoundIcon />}
              bgColor="12, 83, 183"
            />
          </div>
          <div className="col-12 col-md-6 col-lg-3 mb-4">
            <DashboradCountCard
              title="Total Paid Commission"
              count={dashboardData?.paid_commission}
              icon={<CurrencyPoundIcon />}
              bgColor="183, 129, 3"
            />
          </div>
          <div className="col-12 col-md-6 col-lg-3 mb-4">
            <DashboradCountCard
              title="Total Commission Attracted"
              count={dashboardData?.total_commission}
              icon={<CurrencyPoundIcon />}
              bgColor="183, 33, 54"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6 mb-4">
            <h2 className="mb-3">Latest Bookings</h2>
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={dashboardData.latest_bookings}
              className="card-with-background mt-1 h-100"
              hide_search={true}
            />
          </div>
          <div className="col-6 mb-4">
            <h2 className="mb-3">Upcoming Bookings</h2>
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={dashboardData.upcomming_bookings}
              className="card-with-background mt-1 h-100"
              hide_search={true}
            />
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-6 col-md-8 mb-4 ">
            {/* <AppWebsiteVisits height={415} /> */}
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD_MEMBER_ANSWER_LIST}
              data={answersData}
              className="card-with-background"
              is_hide={true}
              title="Latest Member Answers "
              handleNavigateAll={handleNavigateAll}
              hide_footer_pagination={true}
            />
          </div>
          <div className="col-12 col-md-4 mb-4 ps-0">
            <AppNewsUpdate transactions={dashboardData?.transaction} />
          </div>
        </div>
        <CustomDrawer
          isOpenDrawer={drawerState}
          onOpenDrawer={handleOpenDrawer}
          onCloseDrawer={handleCloseDrawer}
          pageTitle="Filter"
          componentToPassDown={
            <DashboardFilter
              getDashboardDetail={getDashboardDetail}
              inputs={inputs}
              setInputs={setInputs}
              onCloseDrawer={handleCloseDrawer}
            />
          }
        />
      </div>
    </div>
  );
}
