import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSnackbar } from "notistack";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { get_root_value } from "src/utils/domUtils";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { LocalizationProvider } from "@mui/x-date-pickers";
import {
  AddProgressAPI,
  DetailProgressApi,
  EditProgressAPI,
  ListProgressCategory,
} from "src/DAL/Progress/Progress";
import { s3baseUrl } from "src/config/config";
import {
  Addbooking,
  AddbookingNew,
  Detailbooking,
  EditBooking,
  bookingPageList,
  bookingPageListAddNew,
  bookingTimeSlots,
} from "src/DAL/Booking/bookingApi";
import { AllMemberForPageNameListing } from "src/DAL/member/Member";

const useStyles = makeStyles(() => ({
  paper: {
    background: get_root_value("--popup-background-color"),
    color: get_root_value("--input-text-color"),
  },
}));
export default function AddOrUpdateBooking({
  editValues,
  dataList,
  formType,
  onCloseDrawer,
  setComments,
}) {
  const { isAccess, userInfo } = useContentSetting();

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const [show, setShow] = useState(true);
  const [state, setState] = useState({
    startDate: new Date(),
    is_notify: false,
    // startDate: null,
  });
  const [selectedName, setSelectedName] = useState(null);
  const [selectedDelegateName, setSelectedDelegateName] = useState(null);
  const [nameList, setNameList] = useState([]);
  const [nameDelegatesList, setNameDelegatesList] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);
  const [salePageList, setSalePageList] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [timeSlotList, setTimeSlotList] = useState([]);

  const [typedText, setTypedText] = useState("");
  const [searchDelegates, setSearchDelegates] = useState("");
  const [startDateMin, setStartDateMin] = useState(new Date());
  const handleChange = (event, newvalue) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const convertTimeZone = (time, fromTimeZone, toTimeZone) => {
    const moment = require("moment-timezone");
    return moment
      .tz(time, "HH:mm", fromTimeZone)
      .tz(toTimeZone)
      .format("hh:mm A");
  };

  const handleChangeStartDate = (event) => {
    setState((prevState) => {
      return {
        ...prevState,
        startDate: event.$d,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // console.log(state, "i am hereeeeee");
    // console.log(selectedName, "i am seleced one");
    // console.log(selectedPage, "i am page");
    // console.log(selectedTime, "i am timee");

    if (selectedName == null) {
      return enqueueSnackbar("Member's name can not be empty !", {
        variant: "error",
      });
    }
    if (selectedPage == null) {
      return enqueueSnackbar("Booking Page can not be empty !", {
        variant: "error",
      });
    }
    if (selectedTime == null) {
      return enqueueSnackbar("Time Slot can not be empty !", {
        variant: "error",
      });
    }
    if (
      selectedDelegateName == null &&
      isAccess.book_call_with_delegate !== "self"
    ) {
      return enqueueSnackbar("Delegates's name can not be empty !", {
        variant: "error",
      });
    }
    setIsLoading(true);
    var startdateString = moment(state.startDate).format("YYYY/MM/DD");
    let consultant;
    if (isAccess.book_call_with_delegate == "self") {
      consultant = userInfo._id;
    } else {
      consultant = selectedDelegateName?._id;
    }
    const data = {
      member_id: selectedName._id,
      page_id: selectedPage._id,
      date: startdateString,
      slot_id: selectedTime.slot_id,
      time: selectedTime.start_time,
      consultant_id: consultant,
    };

    const result = await AddbookingNew(data);
    if (result.code === 200) {
      dataList("", "");
      setIsLoading(false);
      onCloseDrawer();

      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    // console.log(selectedName, "i am updated name");

    // setIsLoading(true);
    var startdateString = moment(state.startDate).format("YYYY/MM/DD");

    const data = {
      page_id: selectedPage._id,
      date: startdateString,
      slot_id: selectedTime.slot_id,
      time: selectedTime.start_time,
      is_notify: state.is_notify,
    };
    // console.log(data, "edited dataa is heree");
    const result = await EditBooking(editValues, data);

    if (result.code === 200) {
      dataList("", "");
      setIsLoading(false);
      onCloseDrawer();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getDetailBooking = async () => {
    const result = await Detailbooking(editValues);
    if (result.code === 200) {
      setSelectedPage(result.booking.page);
      setSelectedTime(result.booking.slot_id);
      console.log(result, "result");

      setState((prevState) => ({
        ...prevState,
        ["startDate"]: result.booking.date,
      }));
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const getNameListing = async () => {
    const result = await bookingPageList(typedText);
    if (result.code === 200) {
      // setNameList(result.members);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getData = async (dataType) => {
    const result = await bookingPageListAddNew(dataType);
    if (result.code === 200) {
      console.log(result, "resultresultresult");

      if (dataType.data_type == "sale_page") {
        setSalePageList(result.data);
      } else if (dataType.data_type == "delegates") {
        setNameDelegatesList(result.data);
      } else {
        setNameList(result.data);
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const fetchMembers = () => {
    let postData = {
      data_type: "members",
      member_type: isAccess.show_members_list_for_booking,
      search_text: typedText,
    };
    getData(postData);
  };
  const fetchDelegates = () => {
    let postData = {
      data_type: "delegates",
      delegates_type: isAccess.other_delegate_team_type,
      search_text: searchDelegates,
    };

    getData(postData);
  };
  const fetchPages = (data) => {
    console.log(data, "called every time");
    let postData = {
      data_type: "sale_page",
      consultant_id: data?._id ? data?._id : userInfo._id,
    };

    getData(postData);
  };
  const getPageListing = async () => {
    const result = await bookingPageList();
    if (result.code === 200) {
      setNameList(result.members);
      setSalePageList(result.Sale_page);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getTimeListing = async (user) => {
    console.log(user, "i am dateeee");
    const dateString = moment(state.startDate).format("YYYY/MM/DD");
    let consultant;
    if (isAccess.book_call_with_delegate == "self") {
      consultant = userInfo?._id;
    } else {
      consultant = user?._id;
    }
    let data = {
      date: dateString,
      consultant_id: consultant,
    };
    const result = await bookingTimeSlots(data);
    if (result.code === 200) {
      // console.log(result.slots, "i amm heree");
      setTimeSlotList(result.slots);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  useEffect(() => {
    if (formType === "EDIT") {
      getDetailBooking();
      setShow(false);
    }
  }, [formType]);
  // useEffect(() => {
  //   getPageListing();
  // }, []);

  // useEffect(() => {
  //   if (typedText.length >= 3) {
  //     getNameListing();
  //   } else {
  //     getPageListing();
  //   }
  // }, [typedText]);

  useEffect(() => {
    // if (typedText) {
    const timeoutId = setTimeout(() => {
      fetchMembers();
    }, 500);
    return () => clearTimeout(timeoutId);
    // }
  }, [typedText]);
  useEffect(() => {
    // if (searchDelegates) {
    const timeoutId = setTimeout(() => {
      fetchDelegates();
    }, 500);
    return () => clearTimeout(timeoutId);
    // }
  }, [searchDelegates]);

  useEffect(() => {
    if (isAccess.book_call_with_delegate !== "self") {
      getTimeListing(selectedDelegateName);
    } else {
      getTimeListing(userInfo);
    }
  }, [state.startDate]);
  useEffect(() => {
    fetchPages("");
  }, []);
  useEffect(() => {
    setSelectedTime(null);
    setSelectedPage(null);
  }, [selectedDelegateName?._id]);

  console.log(selectedDelegateName?._id, "selectedDelegateName?._id");

  return (
    <div className="container new-memories">
      <form onSubmit={formType === "ADD" ? handleSubmit : handleUpdate}>
        {show ? (
          <>
            <FormControl fullWidth className="mt-3">
              <Autocomplete
                id="name"
                options={nameList}
                getOptionLabel={(name) =>
                  name.first_name +
                  " " +
                  name.last_name +
                  " (" +
                  name.email +
                  ")"
                }
                getOptionSelected={(option, value) => option._id === value._id}
                value={selectedName}
                onChange={(event, newValue) => {
                  setSelectedName(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                  setTypedText(newInputValue);
                }}
                onOpen={fetchMembers}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Member"
                    variant="outlined"
                    required
                  />
                )}
              />
            </FormControl>
            {isAccess.book_call_with_delegate !== "self" && (
              <FormControl fullWidth className="mt-3">
                <Autocomplete
                  id="name"
                  options={nameDelegatesList}
                  getOptionLabel={(name) =>
                    name.first_name +
                    " " +
                    name.last_name +
                    " (" +
                    name.email +
                    ")"
                  }
                  getOptionSelected={(option, value) =>
                    option._id === value._id
                  }
                  value={selectedDelegateName}
                  onChange={(event, newValue) => {
                    console.log(newValue, "-----data");
                    setSelectedDelegateName(newValue);
                    fetchPages(newValue);
                    getTimeListing(newValue);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setSearchDelegates(newInputValue);
                  }}
                  onOpen={fetchDelegates}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Delegates"
                      variant="outlined"
                      required
                    />
                  )}
                />
              </FormControl>
            )}
            <FormControl fullWidth className="mt-3">
              <Autocomplete
                id="sale-page"
                options={salePageList}
                getOptionLabel={(page) => page.sale_page_title}
                getOptionSelected={(option, value) => option._id === value._id}
                value={selectedPage}
                onChange={(event, newValue) => {
                  setSelectedPage(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Booking Page*"
                    variant="outlined"
                  />
                )}
                required
              />
            </FormControl>
          </>
        ) : null}

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            format="DD-MM-YYYY"
            mask="__/__/____"
            inputFormat="DD-MM-YYYY"
            minDate={startDateMin}
            label="Date"
            name="startDate"
            value={state.startDate}
            onChange={(e) => handleChangeStartDate(e, "startDate")}
            renderInput={(params) => (
              <TextField {...params} className="mt-3" required={true} />
            )}
          />
        </LocalizationProvider>
        <FormControl fullWidth className="mt-3">
          <Autocomplete
            id="time-slots"
            options={timeSlotList}
            getOptionLabel={(time) => time.start_time + " - " + time.end_time}
            getOptionSelected={(option, value) =>
              option.slot_id === value.slot_id
            }
            value={selectedTime}
            onChange={(event, newValue) => {
              setSelectedTime(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Time Slots*" variant="outlined" />
            )}
            required
          />
        </FormControl>
        {formType === "EDIT" && (
          <FormControl variant="outlined" className="mt-3">
            <InputLabel id="demo-simple-select-outlined-label">
              Is Notifiy User
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={state?.is_notify}
              onChange={(e) => handleChange(e)}
              label="Is Notifiy User"
              name="is_notify"
              className="svg-color"
              MenuProps={{
                classes: {
                  paper: classes.paper,
                },
              }}
              sx={{
                color: get_root_value("--input-text-color"),
              }}
            >
              <MenuItem value={false}>
                <em>No</em>
              </MenuItem>
              <MenuItem value={true}>
                <em>Yes</em>
              </MenuItem>
            </Select>
          </FormControl>
        )}
        <div className="text-end mt-3">
          <button className="submit-button" disabled={isLoading}>
            {isLoading
              ? formType === "ADD"
                ? "Saving..."
                : "Updating..."
              : formType === "ADD"
              ? "Save"
              : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
}
