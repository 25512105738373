import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import ZoomDetail from "./ZoomDetail";
import GroupList from "./GroupList";
import MemberList from "./MemberList";
import RoomUserList from "./RoomUserList";
import { podsDetailApi } from "src/DAL/Pods/Pods";
import ActiveLastBreadcrumb from "src/components/BreadCrums";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function CalendarPodsDetail() {
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const [groupList, setGroupList] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [roomUsers, setRoomUsers] = useState([]);
  const [searchText, setSearchText] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearchText("");
    localStorage.removeItem("pods_search_text");
  };

  const getDetail = async () => {
    setIsLoading(true);
    const result = await podsDetailApi(params?.pod_slug);
    setGroupList(result.room_groups);
    setMemberList(result?.room?.member);
    setRoomUsers(result?.room?.room_user);
    setIsLoading(false);
  };

  useEffect(() => {
    getDetail();
  }, []);

  let breadCrumbMenu = [
    {
      title: state?.pods_name ? state?.pods_name : "Pods",
      navigation: -1,
      active: false,
    },

    {
      title: state?.title,
      active: true,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-6 ">
          <span className="mt-4">
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
        <Box sx={{ width: "100%" }} className="mt-3">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Group List" {...a11yProps(0)} />
              <Tab label="Zoom Credentials" {...a11yProps(1)} />
              <Tab label="Individual Member" {...a11yProps(2)} />
              <Tab label="Exclude Users" {...a11yProps(3)} />
              <Tab label="Room Users" {...a11yProps(4)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <GroupList groupList={groupList} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ZoomDetail zoomObject={state} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <MemberList memberList={memberList} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <RoomUserList
              searchText={searchText}
              setSearchText={setSearchText}
              type="excluded"
              check={false}
            />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <RoomUserList
              searchText={searchText}
              setSearchText={setSearchText}
              type="all"
              check={true}
            />
          </TabPanel>
        </Box>
      </div>
    </div>
  );
}
