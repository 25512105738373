import {
  Checkbox,
  FormControlLabel,
  TextareaAutosize,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export default function CreateSurvey(props) {
  const { surveyInfo, setSurveyInfo, surveyQuestions, setSurveyQuestions } =
    props;

  const handleChangeDates = (name, value) => {
    setSurveyInfo((values) => ({ ...values, [name]: value.$d }));
  };

  const handleChange = (e, index) => {
    const { checked } = e.target;
    const list = [...surveyQuestions];
    list[index].is_multiple_allow = checked;
    setSurveyQuestions(list);
  };

  const handleChangeOptions = (e, index, c_index) => {
    const { name, value } = e.target;
    const list = [...surveyQuestions];
    if (c_index || c_index == 0) {
      list[index]["options"][c_index][name] = value;
    } else {
      list[index][name] = value;
    }
    setSurveyQuestions(list);
  };

  const handleAddQuestion = () => {
    const addedOptions = [
      ...surveyQuestions,
      {
        question_statement: "",
        options: [{ text: "" }, { text: "" }],
        is_multiple_allow: true,
      },
    ];
    setSurveyQuestions(addedOptions);
  };

  const handleDeleteQuestion = (i) => {
    const poll_options = [...surveyQuestions];
    poll_options.splice(i, 1);
    setSurveyQuestions(poll_options);
  };

  const handleAddOption = (index) => {
    const list = [...surveyQuestions];
    list[index].options = [...list[index].options, { text: "" }];
    setSurveyQuestions(list);
  };

  const handleDeleteOption = (index, i) => {
    const poll_options = [...surveyQuestions];
    poll_options[index].options.splice(i, 1);
    setSurveyQuestions(poll_options);
  };

  return (
    <div className="create-poll-box">
      <div className="row">
        <div className="col-12 col-sm-6 mb-2">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Expiry Date"
              name="expiry_date"
              inputFormat="DD-MM-YYYY"
              value={surveyInfo.expiry_date}
              format="DD-MM-YYYY"
              disablePast
              className="mt-3"
              onChange={(e) => handleChangeDates("expiry_date", e)}
              renderInput={(params) => (
                <TextField {...params} required={true} size="small" />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="col-12 col-sm-6 mb-2">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="Expiry Time"
              name="expiry_time"
              disablePast
              value={surveyInfo.expiry_time}
              sx={{ color: "#fff" }}
              className="mt-3"
              onChange={(e) => handleChangeDates("expiry_time", e)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </div>
        <div className="col-12">
          {surveyQuestions?.map((data, index) => {
            return (
              <>
                <div className="d-flex justify-content-between align-items-end mt-1 mb-1 textarea-autosize-box">
                  <div className="poll-question-item">
                    <h4 className="px-1 mb-1 poll-question-statement">
                      {`Question ${index + 1}`}
                    </h4>
                    <TextareaAutosize
                      aria-label="empty textarea"
                      placeholder={`Question Statement`}
                      className="text-color"
                      required
                      value={data.question_statement}
                      minRows={1}
                      name="question_statement"
                      onChange={(e) => handleChangeOptions(e, index)}
                      style={{
                        width: "100%",
                        borderRadius: "8px",
                        background: "transparent",
                        paddingTop: "7px",
                        paddingBottom: "7px",
                        paddingLeft: "15px",
                        color: "white",
                        fontSize: "15px",
                        border: "1px solid var(--input-border-color)",
                      }}
                    />
                    {data.options.map((option, c_index) => {
                      return (
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="mt-2 field-container">
                            <TextareaAutosize
                              aria-label="empty textarea"
                              placeholder={`Option ${c_index + 1}.`}
                              className="text-color"
                              required
                              value={option.text}
                              minRows={1}
                              name="text"
                              onChange={(e) =>
                                handleChangeOptions(e, index, c_index)
                              }
                              style={{
                                width: "100%",
                                borderRadius: "8px",
                                background: "transparent",
                                paddingTop: "7px",
                                paddingBottom: "7px",
                                paddingLeft: "15px",
                                color: "white",
                                fontSize: "15px",
                                border: "1px solid var(--input-border-color)",
                              }}
                            />
                          </div>
                          <div className="text-end">
                            {data.options.length > 2 && (
                              <Tooltip title="Remove">
                                <RemoveCircleOutlineIcon
                                  onClick={() =>
                                    handleDeleteOption(index, c_index)
                                  }
                                  className="remove-poll-options"
                                />
                              </Tooltip>
                            )}
                            {data.options.length < 5 && (
                              <Tooltip title="Add">
                                <AddCircleOutlineIcon
                                  onClick={() => handleAddOption(index)}
                                  className="diary-icon-add"
                                />
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      );
                    })}
                    <div className="ps-1 allow-multiple-options">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data.is_multiple_allow}
                            onChange={(e) => handleChange(e, index)}
                          />
                        }
                        label="Allow Selecting Multiple Options"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  {surveyQuestions.length > 1 && (
                    <Tooltip title="Remove">
                      <RemoveCircleOutlineIcon
                        onClick={() => handleDeleteQuestion(index)}
                        className="remove-poll-options"
                      />
                    </Tooltip>
                  )}
                  {surveyQuestions.length < 5 && (
                    <Tooltip title="Add">
                      <AddCircleOutlineIcon
                        onClick={handleAddQuestion}
                        className="diary-icon-add"
                      />
                    </Tooltip>
                  )}
                </div>
              </>
            );
          })}
        </div>
        <div className="col-12">
          <div className="ps-1 allow-multiple-options">
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(surveyInfo.survey_result === "private")}
                  onChange={(e) => {
                    let survey_result = "public";
                    if (e.target.checked) {
                      survey_result = "private";
                    }
                    setSurveyInfo((old) => ({ ...old, survey_result }));
                  }}
                />
              }
              label="Set result of this survey to be private"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
