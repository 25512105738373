import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Checkbox,
  IconButton,
  CircularProgress,
  FormControlLabel,
} from "@mui/material";
import { useSnackbar } from "notistack";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@mui/styles";
import { get_root_value } from "src/utils/domUtils";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import dayjs from "dayjs";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import { Icon } from "@iconify/react";
import EmailNotifySetting from "../../components/GeneralComponents/CalendarList/EmailNotifySetting";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import MessageSetting from "../../components/GeneralComponents/CalendarList/MessageSetting";
import WhatsappNotifySetting from "../../components/GeneralComponents/CalendarList/WhatsappNotifySetting";
import PushNotifySetting from "../../components/GeneralComponents/CalendarList/PushNotifySetting";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router-dom";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import { memberAndGroupsListApi } from "src/DAL/member/Member";
import {
  add_new_event_api,
  eventDetailApi,
  update_event_iteration_api,
  update_member_event_api,
} from "src/DAL/Calendar/Calendar";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import FullPagePopupForTitle from "src/components/GeneralComponents/CalendarList/FullPagePopupForTitle";
import { TIME_ZONE_CONVERSION } from "src/utils/constants";
import SMSSettingCalender from "src/components/GeneralComponents/CalendarList/SMSSettingCalender";

const useStyles = makeStyles(() => ({
  paper: {
    background: get_root_value("--popup-background-color"),
    color: get_root_value("--input-text-color"),
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const notification_object = {
  notify_before_unit: "minutes",
  notify_before_time: 30,
  notification_title: "",
  notification_send_type: [],
};

export default function AddOrUpdateEvent() {
  const { adminTimeZone, userInfo } = useContentSetting();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { event_slug } = useParams();
  const { defaultTimeZone } = usePGIMode();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [newNotification, setNewNotification] = useState([]);
  const [memberName, setMemberName] = useState([]);
  const [groupsName, setGroupsName] = useState([]);
  const [groupListing, setGroupListing] = useState([]);
  const [serchText, setSerchText] = useState("");
  const [member, setMember] = useState([]);
  const [EmailNotification, setEmailNotification] = useState(false);
  const [drawerState, setDrawerState] = useState("");
  const [selectedObject, setSelectedObject] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [excludeMember, setExcludeMember] = useState([]);
  const iteration_id = new URLSearchParams(location.search).get("iteration_id");
  const [notificationArray, setNotificationArray] = useState([
    notification_object,
  ]);

  const [state, setState] = useState({
    description: "",
    title: "",
    color: "#000",
    recurring_type: "daily",
    notify_before_unit: "minutes",
    notification_send_type: [],
    notify_before_time: 30,
    start_date: dayjs(new Date()).$d,
    weekday: [new Date().getDay()],
    end_date: dayjs(new Date()).$d,
    start_time: dayjs(new Date()).$d,
    end_time: dayjs(new Date()).$d,
    status: true,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleClose = (selected_obj, drawer_state) => {
    let temp_state = [...notificationArray];
    let temp_element = { ...temp_state[selectedIndex] };
    let selected_types = temp_element.notification_send_type;
    if (selected_types.length > 0) {
      selected_types = selected_types.map((item) => {
        if (item.name == drawer_state) {
          item = selected_obj ? selected_obj : selectedObject;
        }
        return item;
      });
    }
    temp_element.notification_send_type = selected_types;
    temp_state[selectedIndex] = temp_element;

    setNotificationArray(temp_state);
    setDrawerState("");
    setSelectedObject({});
    setEmailNotification(false);
    setSelectedIndex(null);
  };

  const handleOpen = (data, index) => {
    setSelectedObject(data);
    setSelectedIndex(index);
    if (data.name == "email_notification_access") {
      setEmailNotification(true);
    } else {
      setDrawerState(data.name);
    }
  };

  const handleChangeNotification = (event, index) => {
    const { name, value } = event.target;
    let temp_state = [...notificationArray];
    let temp_element = { ...temp_state[index] };
    temp_element[name] = value;
    temp_state[index] = temp_element;
    setNotificationArray(temp_state);
  };

  const handleChangeNewNotification = (event, value, index) => {
    let checked = event.target.checked;
    let temp_state = [...notificationArray];
    let temp_element = { ...temp_state[index] };
    if (checked) {
      temp_element.notification_send_type = [
        ...temp_element.notification_send_type,
        value,
      ];
    } else {
      temp_element.notification_send_type =
        temp_element.notification_send_type.filter(
          (item) => item.name !== value.name
        );
    }
    temp_state[index] = temp_element;
    setNotificationArray(temp_state);
  };

  const handleChangeDate = (name, event) => {
    setState((prevState) => {
      return {
        ...prevState,
        [name]: event.$d,
      };
    });
  };

  const handleAddNotification = () => {
    setNotificationArray((old_array) => [...old_array, notification_object]);
  };

  const handleRemoveNotification = (index) => {
    const updatedItems = [...notificationArray];
    updatedItems.splice(index, 1);
    setNotificationArray(updatedItems);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = {
      title: state.title,
      color: state.color,
      description: state.description,
      recurring_type: state.recurring_type,
      status: state.status,
      start_date: moment(state.start_date).format("YYYY-MM-DD"),
      end_date: moment(state.end_date).format("YYYY-MM-DD"),
      start_time: moment(state.start_time).format("HH:mm"),
      end_time: moment(state.end_time).format("HH:mm"),
      is_notify_user: notificationArray.length > 0 ? true : false,
      notify_before: notificationArray,
    };

    if (iteration_id) {
      formData.iteration_id = iteration_id;
    } else {
      let group_array = [];
      if (groupsName.length > 0) {
        group_array = groupsName.map((group) => {
          return {
            group_slug: group.group_slug,
          };
        });
      }

      let selected_member_array = [];
      member.map((member) => {
        selected_member_array.push({
          member_id: member._id,
        });
      });
      let exclude_member_array = [];
      excludeMember.map((member) => {
        exclude_member_array.push({
          _id: member._id,
        });
      });
      formData.group = group_array;
      formData.member = selected_member_array;
      formData.exclude_members = exclude_member_array;
      formData.weekday = state.weekday;
      formData.created_for = "";
    }

    const result = iteration_id
      ? await update_event_iteration_api(formData, event_slug)
      : event_slug
      ? await update_member_event_api(formData, event_slug)
      : await add_new_event_api(formData);
    if (result.code === 200) {
      navigate(-1);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const getGroupsAndMembers = async () => {
    const result = await memberAndGroupsListApi(serchText);
    if (result.code === 200) {
      if (!serchText.length) {
        setGroupListing(result.group);
      }
      let members_array = result.members;
      let members = [];
      if (members_array.length > 0) {
        members = members_array.map((user) => {
          user.full_name =
            user.first_name + " " + user.last_name + " (" + user.email + ")";
          return user;
        });
      }
      setMemberName(members);
      setNewNotification(result.notification_send_types);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const get_start_end_date = (date) => {
    return TIME_ZONE_CONVERSION(
      date,
      "YYYY-MM-DD",
      adminTimeZone,
      userInfo.time_zone
    );
  };

  const get_start_end_time = (date) => {
    return TIME_ZONE_CONVERSION(
      date,
      "YYYY-MM-DD HH:mm",
      adminTimeZone,
      userInfo.time_zone
    );
  };

  const handle_data = (data) => {
    setState({
      ...data,
      start_date: dayjs(get_start_end_date(data.start_date_time)).$d,
      end_date: dayjs(get_start_end_date(data.end_date_time)).$d,
      start_time: dayjs(get_start_end_time(data.start_date_time)).$d,
      end_time: dayjs(get_start_end_time(data.end_date_time)).$d,
      weekday: data.weekday ? data.weekday : [new Date().getDay()],
    });
    let notify_before = [];
    if (data.notify_before?.length > 0) {
      notify_before = data.notify_before;
    }
    setNotificationArray(notify_before);
  };

  const getEventDetail = async () => {
    setIsLoadingPage(true);
    const result = await eventDetailApi(event_slug);
    if (result.code === 200) {
      handle_data(result.event);
      if (iteration_id) {
        let iteration = result.event.iteration_list.find(
          (item) => item._id == iteration_id
        );
        if (iteration) {
          handle_data(iteration);
        }
      }
      setGroupsName(result.event_groups);
      let members_array = result.event_members;
      let exclude_members_array = result?.excluded_members;
      let selected_members = members_array.map((user) => {
        user.full_name =
          user.first_name + " " + user.last_name + " (" + user.email + ")";
        return user;
      });
      let exclude_members = exclude_members_array.map((user) => {
        user.full_name = user?.first_name + " (" + user?.email + ")";
        return user;
      });
      setMember(selected_members);
      setExcludeMember(exclude_members);
      setIsLoadingPage(false);
    } else {
      setIsLoadingPage(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  // useEffect(() => {
  //   getGroupsAndMembers();
  // }, []);

  useEffect(() => {
    if (event_slug) {
      getEventDetail();
    }
  }, []);

  // useEffect(() => {
  //   if (serchText.length > 2) {
  //     getGroupsAndMembers();
  //   }
  // }, [serchText]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getGroupsAndMembers();
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [serchText]);

  useEffect(() => {
    let value = 30;
    if (state.notify_before_unit == "days") value = 7;
    else if (state.notify_before_unit == "hours") value = 24;

    setState((prevState) => {
      return {
        ...prevState,
        notify_before_time: value,
      };
    });
  }, [state.notify_before_unit]);

  let drawer_data = {
    onCloseDrawer: handleClose,
    data: selectedObject,
    drawerState: drawerState,
  };

  const handle_drawer = () => {
    if (drawerState === "message_notification_access") {
      return {
        label: "Message Notification Setting",
        component: <MessageSetting {...drawer_data} />,
      };
    } else if (drawerState === "whatsapp_notification_access") {
      return {
        label: "Whatsapp Notification Setting",
        component: <WhatsappNotifySetting {...drawer_data} />,
      };
    } else if (drawerState === "push_notification_access") {
      return {
        label: "Push Notification Setting",
        component: <PushNotifySetting {...drawer_data} />,
      };
    } else if (drawerState === "sms_notification_access") {
      return {
        label: "SMS Notification Setting",
        component: <SMSSettingCalender {...drawer_data} />,
      };
    }
  };

  if (isLoadingPage == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container-fluid full-page-popup">
        <div className="pt-3 ps-2">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <form className="row px-10" onSubmit={handleSubmit}>
          <div className="text-end mb-4">
            <button
              className="small-contained-button event-submit-button"
              disabled={isLoading}
            >
              {isLoading ? "Submitting..." : "Submit"}
            </button>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <TextField
              className="mt-3"
              id="outlined-basic"
              label="Title"
              variant="outlined"
              name="title"
              value={state.title}
              required={true}
              onChange={handleChange}
            />
          </div>
          <div
            className={`col-12 col-md-6 ${
              iteration_id
                ? "col-lg-4"
                : state.recurring_type == "weekly"
                ? "col-lg-3"
                : "col-lg-4"
            }`}
          >
            <TextField
              id="color"
              type="color"
              label="Color"
              name="color"
              className="mt-3"
              variant="outlined"
              required={true}
              value={state.color}
              onChange={handleChange}
            />
          </div>
          {iteration_id ? (
            <div className="col-12 col-md-6 col-lg-4">
              <FormControl variant="outlined" className="mt-3">
                <InputLabel id="demo-simple-select-outlined-label">
                  Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={state.status}
                  onChange={handleChange}
                  label="Status"
                  name="status"
                  className="svg-color"
                  MenuProps={{
                    classes: {
                      paper: classes.paper,
                    },
                  }}
                  sx={{
                    color: get_root_value("--input-text-color"),
                  }}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
          ) : (
            <>
              <div
                className={`col-12 col-md-6 ${
                  state.recurring_type == "weekly" ? "col-lg-2" : "col-lg-4"
                }`}
              >
                <FormControl variant="outlined" className="mt-3">
                  <InputLabel id="demo-simple-select-outlined-label">
                    Recurring Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={state.recurring_type}
                    onChange={(e) => handleChange(e)}
                    label="recurring Type"
                    name="recurring_type"
                    className="inputs-fields svg-color"
                    MenuProps={{
                      classes: {
                        paper: classes.paper,
                      },
                    }}
                    sx={{
                      color: get_root_value("--input-text-color"),
                    }}
                  >
                    <MenuItem value="daily">Daily</MenuItem>
                    <MenuItem value="weekly">Weekly</MenuItem>
                    <MenuItem value="monthly">Monthly</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {state.recurring_type == "weekly" && (
                <div className={`col-12 col-md-6 col-lg-3`}>
                  <FormControl variant="outlined" className="mt-3">
                    <InputLabel id="demo-simple-select-outlined-label">
                      Select Day
                    </InputLabel>
                    <Select
                      multiple
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={state.weekday}
                      onChange={(e) => handleChange(e)}
                      label="Select Day"
                      name="weekday"
                      className="inputs-fields svg-color"
                      MenuProps={{
                        classes: {
                          paper: classes.paper,
                        },
                      }}
                      sx={{
                        color: get_root_value("--input-text-color"),
                      }}
                    >
                      <MenuItem value={0}>Sunday</MenuItem>
                      <MenuItem value={1}>Monday</MenuItem>
                      <MenuItem value={2}>Tuesday</MenuItem>
                      <MenuItem value={3}>Wednesday</MenuItem>
                      <MenuItem value={4}>Thursday</MenuItem>
                      <MenuItem value={5}>Friday</MenuItem>
                      <MenuItem value={6}>Saturday</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              )}
            </>
          )}
          <div className="col-12 col-md-6 col-lg-3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Start Date"
                name="start_date"
                inputFormat="DD-MM-YYYY"
                value={state.start_date}
                format="DD-MM-YYYY"
                className="mt-3"
                onChange={(e) => handleChangeDate("start_date", e)}
                renderInput={(params) => (
                  <TextField {...params} required={true} />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Start Time"
                name="start_time"
                value={state.start_time}
                sx={{ color: "#fff" }}
                className="mt-3"
                onChange={(e) => handleChangeDate("start_time", e)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="End Date"
                name="end_date"
                inputFormat="DD-MM-YYYY"
                value={state.end_date}
                format="DD-MM-YYYY"
                className="mt-3"
                onChange={(e) => handleChangeDate("end_date", e)}
                renderInput={(params) => (
                  <TextField {...params} required={true} />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="End Time"
                name="end_time"
                value={state.end_time}
                sx={{ color: "#fff" }}
                className="mt-3"
                onChange={(e) => handleChangeDate("end_time", e)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>

          {!iteration_id && (
            <>
              <div className="col-12 col-md-6 col-lg-3">
                <FormControl variant="outlined" className="mt-3">
                  <InputLabel id="demo-simple-select-outlined-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={state.status}
                    onChange={handleChange}
                    label="Status"
                    name="status"
                    className="svg-color"
                    MenuProps={{
                      classes: {
                        paper: classes.paper,
                      },
                    }}
                    sx={{
                      color: get_root_value("--input-text-color"),
                    }}
                  >
                    <MenuItem value={true}>Active</MenuItem>
                    <MenuItem value={false}>Inactive</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className={`col-12 col-md-6 col-lg-3 mt-3`}>
                <MUIAutocomplete
                  inputLabel="Groups"
                  selectedOption={groupsName}
                  setSelectedOption={setGroupsName}
                  optionsList={groupListing}
                  multiple
                  name="title"
                />
              </div>
              <div className={`col-12 col-md-6 col-lg-3 mt-3`}>
                <MUIAutocomplete
                  inputLabel="Members"
                  selectedOption={member}
                  setSelectedOption={setMember}
                  optionsList={memberName}
                  setCustomSearch={setSerchText}
                  name="full_name"
                  multiple
                  onBlur={() => {
                    setSerchText("");
                  }}
                />
              </div>
              <div className={`col-12 col-md-6 col-lg-3 mt-3`}>
                <MUIAutocomplete
                  inputLabel="Exclude Members"
                  selectedOption={excludeMember}
                  setSelectedOption={setExcludeMember}
                  optionsList={memberName}
                  setCustomSearch={setSerchText}
                  name="full_name"
                  multiple
                  onBlur={() => {
                    setSerchText("");
                  }}
                />
              </div>
            </>
          )}
          <div className="col-12 mt-3 mb-4">
            <h5 className="sale-page-title ms-0">Event Detail</h5>
            <hr />
            <div className="mt-4">
              <h4 className="heading-calender">Notifications Setting</h4>
            </div>
            <div className="row">
              {notificationArray.length > 0 &&
                notificationArray.map((notification, index) => {
                  return (
                    <>
                      <div className="col-11 col-md-5 col-lg-7 mt-4">
                        {newNotification.map((notify) => {
                          let find_obj = null;
                          if (notification.notification_send_type?.length > 0) {
                            find_obj = notification.notification_send_type.find(
                              (item) => item.name == notify.name
                            );
                          }

                          return (
                            <span className="position-relative color-white">
                              {find_obj && (
                                <Icon
                                  fontSize="18"
                                  style={{
                                    color: "var(--portal-theme-primary)",
                                  }}
                                  className="me-2 setIconPosition"
                                  icon="circum:edit"
                                  onClick={() => handleOpen(find_obj, index)}
                                />
                              )}
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={(e) =>
                                      handleChangeNewNotification(
                                        e,
                                        notify,
                                        index
                                      )
                                    }
                                    checked={Boolean(find_obj)}
                                    className="p-1"
                                  />
                                }
                                label={notify.label}
                                className="me-3"
                              />
                            </span>
                          );
                        })}
                      </div>
                      <div className="col-11 col-md-6 col-lg-2 ">
                        <FormControl variant="outlined" className="mt-3">
                          <InputLabel id="demo-simple-select-outlined-label">
                            Notify Before
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={notification.notify_before_unit}
                            onChange={(e) => {
                              handleChangeNotification(e, index);
                            }}
                            label="Notify Before"
                            name="notify_before_unit"
                            className="inputs-fields svg-color"
                            MenuProps={{
                              classes: {
                                paper: classes.paper,
                              },
                            }}
                            sx={{
                              color: get_root_value("--input-text-color"),
                            }}
                          >
                            <MenuItem value="days">Days</MenuItem>
                            <MenuItem value="hours">Hours</MenuItem>
                            <MenuItem value="minutes">Minutes</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-11 col-md-5 col-lg-2">
                        <TextField
                          className="mt-3"
                          id="outlined-basic"
                          label={`${
                            notification.notify_before_unit == "days"
                              ? "Days"
                              : notification.notify_before_unit == "hours"
                              ? "Hours"
                              : "Minutes"
                          }`}
                          variant="outlined"
                          name="notify_before_time"
                          type="number"
                          value={notification.notify_before_time}
                          required={true}
                          onChange={(e) => {
                            handleChangeNotification(e, index);
                          }}
                          inputProps={{
                            min: 0,
                            max:
                              notification.notify_before_unit == "days"
                                ? 100
                                : notification.notify_before_unit == "hours"
                                ? 24
                                : 60,
                          }}
                        />
                      </div>
                      <div className="col-1  p-0 mt-4">
                        <Tooltip title="Remove">
                          <RemoveCircleOutlineIcon
                            onClick={() => handleRemoveNotification(index)}
                            className="diary-icon-remove"
                          />
                        </Tooltip>
                      </div>
                    </>
                  );
                })}
              <div className="col-12 mt-3 mb-4">
                <FormControlLabel
                  control={
                    <AddCircleOutlineIcon className="diary-icon-add mx-2" />
                  }
                  label="Add Notification"
                  onClick={handleAddNotification}
                  className="heading-calender"
                />
              </div>
            </div>
            <h4 className="heading-calender">Event Description </h4>
            <GeneralCkeditor
              setInputs={setState}
              inputs={state}
              name="description"
              editorHeight={320}
            />
          </div>
        </form>
      </div>

      <FullPagePopupForTitle
        open={EmailNotification}
        setOpen={setEmailNotification}
        handleClosePopup={handleClose}
        title={selectedObject}
        componentToPassDown={
          <EmailNotifySetting {...drawer_data} open={EmailNotification} />
        }
      />

      <CustomDrawer
        isOpenDrawer={Boolean(drawerState)}
        onCloseDrawer={handleClose}
        pageTitle={handle_drawer()?.label}
        componentToPassDown={handle_drawer()?.component}
      />
    </>
  );
}
