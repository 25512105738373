import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { all_group_members_api } from "src/DAL/Groups/Groups";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import ProgramsInfo from "./ProgramsInfo";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import { useSnackbar } from "notistack";
import { add_challenge_exclude_member } from "src/DAL/Pods/Pods";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const AllMemberList = ({ searchText, setSearchText, type, check }) => {
  const [page, setPage] = useState(0);
  const { group_slug } = useParams();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [openExclude, setOpenExclude] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isAllLoading, setAllIsLoading] = useState(false);
  const [groupAllMembers, setGroupAllMembers] = useState([]);
  const [groupData, setGroupData] = useState("");
  const [selected, setSelected] = useState([]);

  const classes = useStyles();

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "full_name", label: "Name" },
    {
      id: "table_avatar",
      label: "Profile",
      type: "thumbnail",
    },
    { id: "email", label: "Email" },
    {
      id: "program",
      label: "Programmes / Event",
      renderData: (row) => <ProgramsInfo row={row} groupData={groupData} />,
    },
  ];

  const group_members_list = async () => {
    setAllIsLoading(true);
    const result = await all_group_members_api(
      group_slug,
      page,
      rowsPerPage,
      searchText,
      type
    );
    if (result.code == 200) {
      let members = result.group_members.map((item) => {
        let full_name = item.first_name + " " + item.last_name;
        return {
          ...item,
          full_name,
          table_avatar: {
            src: s3baseUrl + item?.profile_image,
            alt: item?.first_name,
          },
        };
      });
      setGroupAllMembers(members);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
      setGroupData(result);
      setAllIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setAllIsLoading(false);
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    group_members_list();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const handleEdit = () => {
    setOpenExclude(true);
  };

  const handleSubmitExclude = async () => {
    let members = selected.map((data) => {
      return { _id: data._id };
    });
    let memberIds = members.map((member) => member._id);
    let postData = {
      slug: group_slug,
      members: members,
      type: "group",
    };
    setOpenExclude(false);
    const result = await add_challenge_exclude_member(postData);
    if (result.code === 200) {
      setGroupAllMembers((prev) => {
        return prev.filter((data) => !memberIds.includes(data._id));
      });
      setSelected([]);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    group_members_list();
  }, [page, rowsPerPage]);

  if (isAllLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div>
      <CustomConfirmation
        open={openExclude}
        setOpen={setOpenExclude}
        title={"Are you sure you want to exclude this member ?"}
        handleAgree={handleSubmitExclude}
      />
      <CustomMUITable
        className="card-with-background mt-3"
        TABLE_HEAD={TABLE_HEAD}
        selected={selected}
        setSelected={setSelected}
        checkbox_selection={check}
        data={groupAllMembers}
        custom_pagination={{
          total_count: totalCount,
          rows_per_page: rowsPerPage,
          page: page,
          handleChangePage: handleChangePage,
          onRowsPerPageChange: handleChangeRowsPerPage,
        }}
        custom_search={{
          searchText: searchText,
          setSearchText: setSearchText,
          handleSubmit: searchFunction,
        }}
        pageCount={pageCount}
        totalPages={totalPages}
        handleChangePages={handleChangePages}
        pagePagination={true}
      />
      {selected.length > 0 && (
        <div class="text-end mt-4" id="fixedbutton">
          <button class="small-contained-button" onClick={handleEdit}>
            Exclude This Members
          </button>
        </div>
      )}
    </div>
  );
};

export default AllMemberList;
