import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";
import {
  DeletePaymentRequestApi,
  _payment_request_detail_api,
  change_status_payment_request_api,
  paymentRequestListApi,
} from "src/DAL/PaymentRequest/paymentRequest";
import Label from "src/components/Label";
import {
  convertCurrencyToSign,
  dd_date_format,
  show_proper_words,
} from "src/utils/constants";
import NewCustomPopover from "src/components/NewCustomPopover";
import BankPaymetDetail from "./BankPaymetDetail";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import ChangePaymentRequestStatus from "./ChangePaymentRequestStatus";
import { useContentSetting } from "src/Hooks/ContentSettingState";

const get_first_paid_status = (row) => {
  return (
    <>
      <Label
        variant="ghost"
        color={row.is_first_paid === false ? "error" : "success"}
      >
        {row.is_first_paid === true
          ? `Paid on ${dd_date_format(row.subscription_date)}`
          : row.payment_status == "cancelled"
          ? `Cancelled on ${dd_date_format(row.cancel_date)}`
          : show_proper_words(row.payment_status)}
      </Label>
    </>
  );
};

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function PaymentRequestsList() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { isAccess } = useContentSetting();

  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [requestsData, setRequestsData] = useState([]);
  const [memberId, setMemberId] = useState("");
  const [sortBy, setSortBy] = useState("pending");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [bankLoading, setBankIsLoading] = useState(false);
  const [showPop, setshowPop] = useState(false);
  const [infoDetail, setInfoDetail] = useState({});
  const [openChangePopup, setOpenChangePopup] = useState(false);
  const [openChangePopupCancelle, setOpenChangeCancelle] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});

  const getRequestsListing = async (status) => {
    setIsLoading(true);
    const result = await paymentRequestListApi(page, rowsPerPage, status);
    if (result.code === 200) {
      let data = result.payment_request.map((item) => {
        let user_name = "N/A";
        let product_name = "N/A";
        let template_name = "N/A";

        if (item.member?.first_name) {
          user_name = item.member?.first_name + " " + item.member?.last_name;
        }
        if (item.product?.name) {
          product_name = item.product?.name;
        }
        if (item.payment_template?.title) {
          template_name = item.payment_template?.title;
        }

        return {
          ...item,
          user_name,
          product_name,
          totalAmount: convertCurrencyToSign(item.currency) + item.total_amount,
          created_at: dd_date_format(item.createdAt),
          initialAmount:
            convertCurrencyToSign(item.currency) + item.initial_amount,
          installmentAmount:
            convertCurrencyToSign(item.currency) + item.installment_amount,
          template_name,
          is_program_access:
            result?.allow_manage_access_programs_in_payment_request,
          is_agreement_configration:
            result?.allow_agreement_configration_in_payment_request,
        };
      });
      setRequestsData(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleChangeSort = (e) => {
    setSortBy(e.target.value);
    getRequestsListing(e.target.value);
    localStorage.setItem("request_filter_status", e.target.value);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    setOpenDelete(false);
    const result = await DeletePaymentRequestApi(
      deleteDoc.payment_request_slug
    );
    if (result.code === 200) {
      setIsLoading(true);
      getRequestsListing(sortBy);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const CopyBankPayment = async (row) => {
    setBankIsLoading(true);
    setMemberId(row._id);
    const result = await _payment_request_detail_api(row._id);
    if (result.code === 200) {
      setInfoDetail(result);
      setshowPop(true);
      setBankIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setBankIsLoading(false);
    }
  };

  const handleChangeRoute = (route, data) => {
    delete data.MENU_OPTIONS;
    navigate(route, { state: data });
  };

  const handleNavigate = () => {
    navigate(`/payment-request/add-payment-request`);
  };

  const handleEdit = (value) => {
    navigate(
      `/payment-request/edit-payment-request/${value.payment_request_slug}`,
      {
        state: value,
      }
    );
  };

  const handlemanageProgramAccess = (value) => {
    let route = `/payment-request/payment-program-access/${value._id}`;
    handleChangeRoute(route, value);
  };

  const handleBilling = (value) => {
    navigate(
      `/payment-request/payment-request-detail/${value.payment_request_slug}`,
      {
        state: value,
      }
    );
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "user_name", label: "Member" },
    {
      id: "request_title",
      label: "Request Title",
      handleClick: (row) => handleBilling(row),
      className: "cursor-pointer",
    },
    { id: "product_name", label: "Product" },
    { id: "template_name", label: "Payment Template" },
    { id: "request_type", label: "Request Type", className: "text-capitalize" },
    { id: "totalAmount", label: "Total Amount" },
    { id: "initialAmount", label: "Initial Amount" },
    { id: "installmentAmount", label: "Installment Amount" },
    { id: "month", label: "Month" },
    {
      id: "firstPaid",
      label: "First Paid",
      renderData: (row) => get_first_paid_status(row),
    },
    { id: "created_at", label: "Created At" },
    { id: "status", label: "Status", type: "row_status" },
    { id: "action", label: "Action", type: "action" },
  ];

  const handleAgreementConfig = (value) => {
    let route = `/payment-request/payment-agreement-config/${value._id}`;
    handleChangeRoute(route, value);
  };

  const handleOpenChangeStatusPopUp = (value) => {
    setOpenChangePopup(true);
    setSelectedObject(value);
  };
  const handleOpenChangeStatusCancle = (value) => {
    setOpenChangeCancelle(true);
    setSelectedObject(value);
  };

  const handle_menus = (row) => {
    let {
      action_by,
      show_on_consultant,
      request_type,
      payment_status,
      is_first_paid,
      is_program_access,
      is_agreement_configration,
    } = row;
    const MENU_OPTIONS = [];
    if (
      (action_by == "admin_user" && show_on_consultant == "all") ||
      action_by == "consultant_user"
    ) {
      if (!is_first_paid) {
        MENU_OPTIONS.push({
          label: "Edit",
          icon: "akar-icons:edit",
          handleClick: handleEdit,
        });
      }
      MENU_OPTIONS.push({
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleAgreeDelete,
      });

      if (
        isAccess.show_option_mark_request_as_paid &&
        row.request_type === "onetime" &&
        row.payment_status === "pending"
      ) {
        MENU_OPTIONS.push({
          label: "Mark Request As paid",
          icon: "akar-icons:edit",
          handleClick: handleOpenChangeStatusPopUp,
        });
      }

      if (
        isAccess.show_option_mark_request_as_cancelled &&
        row.payment_status === "pending"
      ) {
        MENU_OPTIONS.push({
          label: "Mark Request As Cancelled",
          icon: "akar-icons:edit",
          handleClick: handleOpenChangeStatusCancle,
        });
      }
    }
    MENU_OPTIONS.push({
      label: "View Detail",
      icon: "akar-icons:eye",
      handleClick: handleBilling,
    });
    if (
      request_type == "onetime" &&
      !is_first_paid &&
      payment_status == "pending"
    ) {
      MENU_OPTIONS.push({
        label: "Copy Bank Payment Link",
        icon: "akar-icons:eye",
        handleClick: CopyBankPayment,
      });
    }
    if (is_agreement_configration) {
      MENU_OPTIONS.push({
        label: "Agreement Configuration",
        icon: "akar-icons:edit",
        handleClick: handleAgreementConfig,
      });
    }

    if (is_program_access) {
      MENU_OPTIONS.push({
        label: "Manage Programme Access",
        icon: "akar-icons:edit",
        handleClick: handlemanageProgramAccess,
      });
    }

    return MENU_OPTIONS;
  };

  const handleChangeStatus = async () => {
    setIsLoading(true);
    let postData = {
      type: "cancelled",
      payment_request_slug: selectedObject.payment_request_slug,
    };

    const result = await change_status_payment_request_api(postData);

    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
      setOpenChangeCancelle(false);
      getRequestsListing(sortBy);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let type = "pending";
    let filter_status = localStorage.getItem("request_filter_status");
    if (filter_status) {
      type = filter_status;
      setSortBy(type);
    }
    getRequestsListing(type);
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this payment request?"}
        handleAgree={handleDelete}
      />
      <CustomConfirmation
        open={openChangePopupCancelle}
        setOpen={setOpenChangeCancelle}
        title={"Are you sure you want to cancel this payment request?"}
        handleAgree={handleChangeStatus}
      />

      <div className="container">
        <div className="row mb-3 mt-4">
          <div className="col-lg-6 col-sm-12">
            <h2>Payment Requests</h2>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="d-flex">
              <FormControl size="small">
                <InputLabel id="demo-simple-select-label">Sort By</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={sortBy}
                  label="Sort By"
                  onChange={handleChangeSort}
                  className="me-4"
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="paid">Paid</MenuItem>
                  <MenuItem value="processing">Processing</MenuItem>
                  <MenuItem value="cancelled">Cancelled</MenuItem>
                </Select>
              </FormControl>
              <button
                onClick={handleNavigate}
                className="small-contained-button text-nowrap"
              >
                Add Payment Requests
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={requestsData}
              handle_menus={handle_menus}
              className="card-with-background mt-3"
              localSearchName={"payment_request_search"}
            />
          </div>
        </div>
      </div>
      <GeneralPopUpModel
        open={openChangePopup}
        setOpen={setOpenChangePopup}
        title={
          selectedObject?.request_title ? selectedObject?.request_title : ""
        }
        componentToPassDown={
          <ChangePaymentRequestStatus
            selectedObject={selectedObject}
            setOpenChangePopup={setOpenChangePopup}
            getMemberListing={getRequestsListing}
            filterData={sortBy}
          />
        }
      />
      <NewCustomPopover
        isOpenPop={showPop}
        isClosePop={setshowPop}
        title={"Payment Request Detail"}
        componentToPassDown={
          <BankPaymetDetail
            infoDetail={infoDetail}
            bankLoading={bankLoading}
            memberId={memberId}
            setshowPop={setshowPop}
          />
        }
      />
    </>
  );
}
