import React, { useEffect, useState } from "react";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import LinkList from "./LinkList";
const LinksTabs = () => {
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    localStorage.setItem("link_tab_value", newValue);
  };
  const TABS_OPTIONS = [
    {
      title: <div className="d-flex">Sales Pages</div>,
      component: <LinkList type="sale_page" />,
    },
    {
      title: <div className="d-flex">Booking Pages</div>,
      component: <LinkList type="book_a_call_page" />,
    },
  ];
  useEffect(() => {
    const tabValue = localStorage.getItem("link_tab_value");
    if (tabValue) {
      setTabValue(parseInt(tabValue));
    }
  }, []);

  return (
    <div className="container">
      <div className="row section-space">
        <div className="col-sm-12 col-md-6 mb-2">
          <h2>Links</h2>
        </div>
        <div className="col-12">
          <MUICustomTabs
            data={TABS_OPTIONS}
            value={tabValue}
            handleChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default LinksTabs;
